import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Canvas } from '@react-three/fiber'
import { useGLTF, OrbitControls} from '@react-three/drei'
import Capçal from '../Capçal/Capçal.component'
import { Fondo } from './Papir3D.styles'


export default function Papir3D() {

        useEffect(() => {
          setTimeout({},300)
        }, [])

    const location = useLocation();
    const {imatge3D} = location.state.dadesActuals
    const data = location.state.dadesActuals
    console.log("dades de location",location)
    
    function Model({ url, ...props }) {
        const { scene } = useGLTF(url)
        return <primitive object={scene} {...props} />
    }

    return(
        <Fondo>
            <Capçal numSerie={location.state.numSerie.numSerie} comingFrom={location.pathname} data={data}/>
            <Canvas camera={{ position: [2, 1, 2], fov: 25 }}>
                <hemisphereLight color="white" groundColor="blue" intensity={0.75} />
                <spotLight position={[50, 50, 10]} angle={0.15} penumbra={1} />
                <Model position={[0, 0, 0]} url={process.env.PUBLIC_URL + imatge3D} />         
                <OrbitControls />
            </Canvas>
        </Fondo>
    )
}