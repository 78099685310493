import styled from 'styled-components'
import Lupa from '../../resources/lupa.png'

export const Capçalera = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin: 0;
    margin-top: 5px;
    width: 1145px;
    height: 150px;
    //border: 2px solid yellow;
`;

export const NavMenu = styled.nav`
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    transition: all 0.6s ease;
    margin-top: 35px;

`;

export const LinkMenu = styled.a`

    color: white;
    font-size: 22px;
    text-decoration: none;
    //font-weight: bold;
    :hover{
        cursor: pointer;
    }

`;

export const Icones = styled.img`
    
    height: 60px;
    align-self: center;
    padding-top: 10px;
    opacity: ${props => props.actiu ? "100%" : "20%"};

    :hover{
        opacity: ${props => props.actiu ? "60%" : "20%"};
        cursor: ${props => props.actiu ? "pointer": "auto"};
    }    
`;

export const IconaMenu = styled.img`
    
    height: 30px;
    align-self: center;
    opacity: ${props => props.actiu ? "100%" : "20%"};

    :hover{
        opacity: ${props => props.actiu ? "60%" : "20%"};
        cursor: ${props => props.actiu ? "pointer": "auto"};
    }
    
`;

export const Search = styled.input`
    
    background-image: url(${Lupa});
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-color: transparent;
    background-position: 0.4rem;
    text-indent: 3rem;
    height: 2rem;
    width: 15rem;
    border-radius: 23px;
    border-width: 2px;
    color: white;
    border-color: white;
    font-size: 2rem;
    align-self: center;    

`;

export const LogoMini = styled.img`
    width: 390px;
    height: 130px;
    align-self: center;
    padding-top: 5px;

    :hover{
        cursor: pointer;
    }
`;

export const NSerie = styled.label`

    align-self: center;
    font-size: 20px;

`;