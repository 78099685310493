import { createContext, useState, useEffect } from "react";

export const ScanContext = createContext({
    escanejat: false,
    setEscanejat: ()=>{}
});

export const ScanProvider = ({children}) => {
    const [escanejat, setEscanejat ] = useState(false);

    // useEffect(()=>{
    //     localStorage.setItem(`${sessionToken}`, JSON.stringify(sessionToken))
    // },[sessionToken]);

    // useEffect(() => {
    //     console.log("canvi estat contexte..",escanejat);
    //   }, [escanejat]);

    const value = {
                    escanejat, setEscanejat,
                };

    return(
        <ScanContext.Provider value={value}>{children}</ScanContext.Provider>
    );

};