import React, { useState, useEffect } from 'react';
import { ContainerVertical, ImatgeScan, Paragraf, Imatge } from './CapturaQR.styles';
import QrScan from 'react-qr-reader';
import { getDatabase, ref, onValue } from 'firebase/database';
import { app, updateData } from '../../utils/firebase.utils';
import LogoOK from '../../resources/LogoOK.png'
import LogoKO from '../../resources/LogoKO.png'


const CapturaQR = ({token}) => {

    const [isScaned, setIsScaned] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const Key = "0x65b202ff951edbc9017c13c32e9744f12d5df41742a0c45a5043693f0a862fed"
    const Key1 = "0xe82936f66953fb5c9b7fd0690d681d9196b86c338ab9916a12984fb48d533164" 
    const Key2 = "0x3492a59a88a1504bb6c086c059aefe81d1195e35107c4b2c4fb3c2e7a7a8e78a" 
    const Key3 = "0x65b202ff951edbc9017c13c32e9744f12d5df41742a0c45a5043693f0a862fed" 
    
    useEffect(() => {

        const database = getDatabase(app);
        const dataRoute = 'TokenSessio/'+token+'/PKisScaned'
        const validesa = ref(database, dataRoute);
        onValue(validesa, (snapshot) => {
            const data = snapshot.val();
            setIsScaned(data);            
        });

        const dataRoute2 = 'TokenSessio/'+token+'/PKisValid'
        const validesa2 = ref(database, dataRoute2);
        onValue(validesa2, (snapshot2) => {
            const data2 = snapshot2.val();
            setIsValid(data2);            
        });
    }, [])
    
    
    const handleScan = (result) => {
        if (result!==null && result===Key1) {
            updateData(token,'PKisScaned', true);
            updateData(token, 'PKisValid', true);
            updateData(token, 'Nserie', "MmCaT - f_306");
            
        }else if(result!==null && result===Key2){
            updateData(token,'PKisScaned', true);
            updateData(token, 'PKisValid', true);
            updateData(token, 'Nserie', "MmCaT - n_0963");
        
        }else if(result!==null && result!==Key1){
            updateData(token,'PKisScaned', true);
            updateData(token, 'PKisValid', false);
            updateData(token, 'Nserie', "");
        
        }else if(result!==null && result!==Key2){
            updateData(token,'PKisScaned', true);
            updateData(token, 'PKisValid', false);
            updateData(token, 'Nserie', "");
        }
      };
    
      const handleError = (error) => {
        if(error) {console.log("no va gens de be..")};
      };
    
    if(isScaned && !isValid){
        return(
            <ContainerVertical>
                <ImatgeScan>
                    <Imatge src={LogoKO} />
                </ImatgeScan>
                <Paragraf>Clau Privada errònia o no reconeguda..!</Paragraf>
            </ContainerVertical>
        )
    }

    else if(isScaned && isValid){
        return(
            <ContainerVertical>
                <ImatgeScan>
                    <Imatge src={LogoOK} />
                </ImatgeScan>
                <Paragraf>Clau Privada Correcta..!!</Paragraf>
            </ContainerVertical>
        )
    }
    
    return(

            <ContainerVertical>
                <ImatgeScan>
                <QrScan
                        showViewFinder = {true}
                        facingMode= 'environment'
                        scanDelay={30}
                        onScan={(data)=> handleScan(data)}
                        onError={(error)=> handleError(error)}
                        video = { //any valid JS-CSS can be added here
                            { width: '50%',
                            height: '85%'}}
                        style={{ width: "180px",position: 'relative', left: '7vw', top: '-1vh' }}
                        viewFinder = {{ //any valid JS-CSS can be added here
                            border: '12px solid rgba(255,255,255,0.3)',
                            position: 'absolute',
                            borderRadius: '5px',
                            width: '500px',
                            height: '500px'}}
                            resolution = {600}
                        />
                </ImatgeScan>                    
                <Paragraf>
                    Escaneji la clau Privada del paper wallet,
                    on és diposat el token <b>MmCaT</b>
                </Paragraf>
            </ContainerVertical>
    );
};

export default CapturaQR;