import styled from 'styled-components'
import ImatgeFondo from '../../resources/AndorraPell.jpg'
import Lupa from '../../resources/lupa.png'

export const Fondo = styled.div`

  justify-items: center;
  align-items: center;
  background-color: #011d33;
  width: 100%;
  height: 100%;

`;

export const Capçalera = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin: 0;
    margin-top: 1em;
    width: 1145px;
    height: 6vh;
    //border: 2px solid yellow;
`;

export const ContainerVertical = styled.div`

    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    width: 100%;
    height: 98vh;
    align-items: flex-start;
    color: white;
    background-image: url(${ImatgeFondo});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat;
    //border: 2px solid white;
`;

export const ContainerVerticalNarrow = styled.div`

    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    width: 80%;
    height: 80vh;
    margin-left: 10vw;
    margin-top: 0;
    padding: 2em;
    padding-top: 0;
    align-items: flex-start;
    justify-self: center;
    color: white;
    //border: 2px solid red;
`;

export const ContainerHoritzontal = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    //height: 80vh;
    //border: 2px solid black;
`;

export const ContainerHorizontalNarrow = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin-left: 6em;
    padding: 0.5em;
    //border: 2px solid orange;
`;

export const ContainerTitols = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin-left: 7rem;
    padding-top: 0;
    padding: 0;
    //border: 2px solid orange;
`;

export const Titol = styled.h3`
    
    margin: 0;
`

export const Search = styled.input`
    
    background-image: url(${Lupa});
    background-repeat: no-repeat;
    background-size: 2rem;
    background-color: transparent;
    background-position: 0.4rem;
    text-indent: 3rem;
    height: 2rem;
    width: 15rem;
    border-radius: 23px;
    border-width: 2px;
    color: white;
    border-color: white;
    font-size: 2rem;
    align-self: center;    

`;

export const Logo = styled.img`
    width: 55em;
    height: 15em;
    align-self: center;
    
`;

export const Epub = styled.img`
    width: 5em;
    height: 5em;
`;

export const LogoMini = styled.img`
    width: 18em;
    height: 6em;
    align-self: center;
    padding-top: 0.5em;
`;

export const Button = styled.button`
    
    border: 1px solid white;
    border-radius: 6px;
    height: 3em;
    width: 20em;
    color: white;
    font-size: 1em;
    font-weight: bold;
    background-color: transparent;

`;

export const Cuadro = styled.label`
    
    border: 1px solid white;
    border-radius: 10px;
    height: 5em;
    width: 18em;
    text-align: center;
    text-justify: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1rem;
`;

export const Cuadro2 = styled.label`
    
    flex-wrap: wrap;
    //border: 1px solid white;
    height: 16rem;
    width: 27rem;
    text-align: start;
    text-justify: center;
    font-size: 15px;
    margin: 0.5rem;
    margin-top: 0;
    padding: 1rem;
`