import React from 'react';
import {Routes, Route} from "react-router-dom";
import AccesTokenSessio from './routes/AccesTokenSessio/AccesTokenSessio.component';
import Mobile from './routes/mobile/mobile.component';
import MobileSlug from './routes/mobile/mobile-slug.component';
import PaginaDetalls from './components/PaginaDetalls/PaginaDetalls.component';
import Papir3D from './components/Papir3D/Papir3D.component';
import Timeline from './components/Timeline/Timeline.component';
import TimeLineLibrary from './components/Timeline/TimelineLibrary/TimelineLibrary.component'
import Epub from './components/Epub/Epub.component';
import './App.css';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Mobile/>} />
          <Route path="/AccesTokenSessio" element={<AccesTokenSessio />} />
          <Route path="/mobile/:tokenId" element={<MobileSlug/>} />
          <Route path="/detalls" element={<PaginaDetalls/>} />
          <Route path="/Papir3D" element={<Papir3D/>} />
          <Route path="/Timeline" element={<Timeline/>} />
          <Route path="/TimelineLibrary" element={<TimeLineLibrary/>} />
          <Route path="/Epub" element={<Epub/>} />        
        </Routes>
      </div>
  );
}

export default App;
