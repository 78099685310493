import { Fondo, Imatge, Titol, ContainerHoritzontal } from './PantallaMobil.styles'
import Logo from '../../resources/cathalonia-logo-invertit.png'
import CapturaQR from '../CapturaQR/capturaQR.component'


export default function PantallaMobil({token}) {

    return(
        <div>
            <Fondo>
                <ContainerHoritzontal>
                    <Imatge src={Logo} />
                    <Titol>Cathalonia digital</Titol>
                </ContainerHoritzontal>
                <CapturaQR token={token} />
            </Fondo>
            
        </div>

    )
}