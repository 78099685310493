import { useNavigate, useLocation } from 'react-router-dom'
import {Fondo, ContainerHorizontalNarrow, IconesCarousel, 
        ContainerCarousel, ContainerVertical, ContainerVertical2, DivComentari, 
        DivIcones, Icones, Anterior, Posterior } from './PaginaDetalls.styles'
import TresD50 from '../../resources/3D100.png'
import Epub50 from '../../resources/Epub100.png'
import Ld50 from '../../resources/HD100.png'
import Pdf50 from '../../resources/Pdf100.png'
import Next from '../../resources/Next.png'
import Capçal from '../Capçal/Capçal.component'
import { auth, CheckDatabase } from '../../utils/firebase.utils'
import { useEffect, useState } from 'react'
import Carousel from 'nuka-carousel/lib/carousel'

export default function PaginaDetalls() {
    const location = useLocation();
    const {miniatures, imatgesHQ, comentari, ComentariEpub, titol, autor, tresD, HD, ePub, pdf} = location.state.data
    const dadesActuals = location.state.data
    //console.log("dades....", dadesActuals)
    const [numSerie, setNumSerie] = useState("")
    const [pagina, setPagina] = useState(0)
    
    useEffect(() => {
        setTimeout(() => {
            //if(auth.currentUser) setConectat(true);
            CheckDatabase(auth.currentUser.uid, (data, error) =>{
                if(error){
                    console.log(error)
                }
                if(data) {
                    setNumSerie(data.Nserie);
                }    
              });         
        }, 300);    
    }, [])
    
    
    const navigate = useNavigate();


    const threeD= ()=>{
        if(tresD){
            //window.open("/Papir3D", { state: { imatge3D }},"", "popup, menubar=no, titlebar=no, toolbar=no")
            navigate("/Papir3D",  { state: { dadesActuals, numSerie: {numSerie} }})
        }
    }
    const LowD = () =>{
        if(HD){
            window.open(imatgesHQ[pagina],"", "popup, menubar=no, titlebar=no, toolbar=no")
            //window.open(miniatures,"", "popup, menubar=no, titlebar=no, toolbar=no")
            console.log("pagina actual.....",pagina)
        }
    }
    const Epub = () =>{
        navigate("/Epub",  { state: {URL:ComentariEpub, numSerie} })
    }

    return(
        <Fondo>
            <Capçal numSerie={numSerie} comingFrom={location.pathname} data={dadesActuals}/>
            <ContainerHorizontalNarrow>
                <ContainerVertical2>
                    <h2  >{titol}</h2>
                </ContainerVertical2>
            </ContainerHorizontalNarrow>            
            <ContainerHorizontalNarrow>
                <ContainerVertical2>
                    <h3 style={{padding:"0", margin:"0", alignSelf:"flex-start"}} >{autor}</h3>
                    <DivComentari> {comentari}  </DivComentari>
                </ContainerVertical2>
                <ContainerVertical>
                    <label>Comentari</label>
                    <DivIcones>
                        <Icones src={Epub50} actiu={ePub} onClick={Epub} />
                        <Icones src={Pdf50} actiu={pdf} />
                    </DivIcones>                
                </ContainerVertical>
            </ContainerHorizontalNarrow>
            <ContainerCarousel>
                <Carousel adaptiveHeight={true} slidesToShow={1} cellSpacing={0} style={{ width:"1145px"}}
                    renderTopCenterControls={({ currentSlide }) => (
                        setPagina(currentSlide)
                    )}
                    defaultControlsConfig={{
                    prevButtonText: <Anterior src={Next}/>,
                    nextButtonText: <Posterior src={Next}/>,
                  }}>
                    {miniatures.map((url, index)=>{
                        //console.log(index, url)
                        return(
                            <div key={index} style={{ textAlign: "center" }}>
                                <img style={{height:"400px"}} src={url}/> 
                            </div>
                        )
                    })}
                </Carousel>
                <IconesCarousel>
                    <Icones src={TresD50} actiu={tresD} onClick={threeD} />
                    <Icones src={Ld50} actiu={HD} onClick={LowD} />
                </IconesCarousel>
            </ContainerCarousel>

        </Fondo>
    )
}