import styled from 'styled-components'


export const Fondo = styled.div`

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #122034;
`;

export const Imatge = styled.img`
    
    align-self: center;
    padding-top: 6em;
    width: 1145px;
    //height: 30vh;
`;

export const Titol = styled.h1`

    margin-top: 15vh;    
    font-family: Helvetica;
    font-size: 40px;
    color: #eeb82c;
`;

export const Paragraf = styled.p`

    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    line-height: 30px;
    //margin: 8vh;
`;

export const ContainerHoritzontal = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
`;

export const Boto = styled.button`

    border: 3px solid #eeb82c;
    color: white;
    border-radius: 26px;
    padding: 10px;
    /* font-size: 20px; */
    background-color: #122034;
    font-size: x-large;
    margin: 3em;

        :hover{
            cursor: pointer;
        }
`;