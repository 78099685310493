import { useState, useEffect } from 'react'
import ImatgeQR from '../../components/ImatgeQR/ImatgeQR.component'
import { getDatabase, ref, onValue } from "firebase/database"
import { auth, app, CheckDatabase} from '../../utils/firebase.utils'
import { Container, Logo, Paragraf } from './AccesTokenSessio.styles'
import logo from '../../resources/LogoCat.png'
import EscaneixClauPrivada from '../../components/EscaneixClauPrivada/EscaneixClauPrivada.component';

export default function AccesTokenSessio() {

    let [value, setValue] = useState('');
    const [escanejat, setEscanejat] = useState(false);
    const [isScaned, setIsScaned] = useState(false);
    const [isValid, setIsValid] = useState(false);
  
    useEffect(() => {
      
        setTimeout(()=>{
          //let actualUrl = window.location.href
          //console.log(window.RTCPeerConnection);
          //let finalUrl = actualUrl.substring(0, actualUrl.length-16);
          //const url = "https://beamish-sawine-b0ee01.netlify.app/mobile/"+auth.currentUser.uid;
          //const url = "http://10.176.4.16/"+"mobile/"+auth.currentUser.uid
          const url = "https://nft.mementoedicions.com/mobile/"+auth.currentUser.uid
          setValue(url);
          console.log(url);
          CheckDatabase(auth.currentUser.uid, (data, error) =>{
            if(error){
                console.log(error)
            }
            if(data) {
              setEscanejat(data.QRisScaned);
            }    
          });
          const database = getDatabase(app);
          const dataRoute = 'TokenSessio/'+auth.currentUser.uid+'/QRisScaned'
          const escaneig = ref(database, dataRoute);
          onValue(escaneig, (snapshot) => {
              const data = snapshot.val();
              setEscanejat(data);
              
          });
          const dataRoute3 = 'TokenSessio/'+auth.currentUser.uid+'/PKisScaned'
          const validesa3 = ref(database, dataRoute3)
          onValue(validesa3, (snapshot3) => {
              const data3 = snapshot3.val()
              setIsScaned(data3)            
          });
  
          const dataRoute2 = 'TokenSessio/'+auth.currentUser.uid+'/PKisValid'
          const validesa2 = ref(database, dataRoute2)
          onValue(validesa2, (snapshot2) => {
              const data2 = snapshot2.val()
              setIsValid(data2)            
          });
        },300);
    }, []);
    
    return(
        <>
        {escanejat ? 
          <EscaneixClauPrivada/>
          :<Container>
            <Logo src={logo}/>
            <ImatgeQR value={value} />
            <Paragraf>Escanegi aquest codi QR amb el seu telèfon mòbil</Paragraf>
          </Container>
        }
        </>
    )
}