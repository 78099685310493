import {Fondo, Imatge, 
        Titol, Paragraf, Boto,
        ContainerHoritzontal } from './PrivadaErronia.style'
import Logo from '../../resources/LogoCat.png'
import { useNavigate } from 'react-router-dom';


export default function PrivadaErronia() {

    const navigate = useNavigate();
    const navega = () => {
        navigate("/", { replace: true });
    };

return(
    <>
    <Fondo>
        <Imatge src={Logo}/>
        <Titol>ERROR A L'ACCEDIR AL CONTINGUT DIGITAL</Titol>
        <Paragraf>Per accedir al contingut digital, cal ser propietari d'un
           <b> MementoCathaloniaToken - 1 MmCaT -</b>
        </Paragraf>
        <ContainerHoritzontal>
            <Boto onClick={navega} ><b>Torna a iniciar Sessió</b></Boto>
        </ContainerHoritzontal>
    </Fondo>
    
    </>
)

}