import styled from 'styled-components';

export const Container = styled.div`
    
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    //width: 1145px;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: #011d33;
`;

export const ContainerHoritzontal = styled.div`

    display: flex;
    flex-direction: row;
    width: 1145px;
    align-items: center;
    justify-content: space-around;
`;

export const ContainerVertical = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 1145px;
    align-items: center;
    margin: 5.3%;
    /* justify-content: space-around; */
`;

export const Logo = styled.img`
    width: 1145px;
    //height: 30vh;
`;

export const Titol = styled.h1`

    font-family: Helvetica;
    font-size: 60px;
    color: white;
`;

export const SubTitol = styled.h2`

    font-family: Helvetica;
    color: white;
`; 

export const Paragraf = styled.p`

    background-color: #011d33;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    line-height: 35px;
    margin: 4vh;
`;

export const Paragraf2 = styled.p`

    width: 1145px;
    //height: 100%;
    text-align: start;
    padding-left: 4em;
    padding-right: 0;
    background-color: #011d33;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    
`;

export const Boto = styled.button`

    border: 4px solid white;
    color: white;
    border-radius: 26px;
    padding: 10px;
    margin: 1.65em;
    /* font-size: 20px; */
    background-color: #011d33;
    font-size: large;

        :hover{
            cursor: pointer;
        }
`;