import styled from 'styled-components'
import AndorraPell from '../../resources/AndorraPell.jpg'

export const Fondo = styled.div`

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #011d33;
    /* background-image: url(${AndorraPell});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat; */
`;

export const ContainerHoritzontal = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;

`;

export const Imatge = styled.img`
    
    margin-top: 3vh;
    width: 120px;
    height: 100px;
`;

export const Titol = styled.h1`

    margin-top: 9vh;    
    font-family: Helvetica;
    font-size: 25px;
    color: white;
`;