export const medieval = [
    {id: "001",
    data: "815",
    titol: "Compravenda a la vila de Codinet (815)",
    subtitol: "El document original més antic conservat a Catalunya",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_001_01.png",
            "miniatures/LQ/LQ_001_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_001_01.png",
        "miniatures/HQ/HQ_001_02.png"
    ],
    miniatura: "miniatures/TL/TL_001.png",
    imatge3D: "miniatures/001/001.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "002",
    data: "844",
    titol: "Privilegi de Santa Maria d'Amer (844)",
    subtitol: "El primer precepte carolingi original conservat a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_002_01.png",
            "miniatures/LQ/LQ_002_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_002_01.png",
        "miniatures/HQ/HQ_002_02.png"
    ],
    miniatura: "miniatures/TL/TL_002.png",
    imatge3D: "miniatures/002/002.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "003",
    data: "877",
    titol: "Carta de Carles el Calb als barcelonins (877)",
    subtitol: "Barcelona, l’avantguarda de la frontera carolíngia amb al-Àndalus",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_003_01.png",
            "miniatures/LQ/LQ_003_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_003_01.png",
        "miniatures/HQ/HQ_003_02.png"
    ],
    miniatura: "miniatures/TL/TL_003.png",
    imatge3D: "miniatures/003/003.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "004",
    data: "888",
    titol: "Consagració de Sant Andreu de Tona (888)",
    subtitol: "El ritual catalano-narbonès i la primera notació musical",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_004_01.png",
            "miniatures/LQ/LQ_004_01.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_004_01.png",
        "miniatures/HQ/HQ_004_02.png"
    ],
    miniatura: "miniatures/TL/TL_004.png",
    imatge3D: "miniatures/004/004.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "005",
    data: "913",
    titol: "Reconeixement al monestir de Sant Joan de les Abadesses (913)",
    subtitol: "Els hereus de Guifré: el naixement d’un llinatge",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_005_01.png",
            "miniatures/LQ/LQ_005_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_005_01.png",
        "miniatures/HQ/HQ_005_02.png"
    ],
    miniatura: "miniatures/TL/TL_005.png",
    imatge3D: "miniatures/005/005.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "006",
    data: "971",
    titol: "Butlla papal a favor d’Ató de Vic (971)",
    subtitol: "El primer intent de creació d’un arquebisbat català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_006_01.png",
            "miniatures/LQ/LQ_006_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_006_01.png",
        "miniatures/HQ/HQ_006_02.png"
    ],
    miniatura: "miniatures/TL/TL_006.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "007",
    data: "1000",
    titol: "Venda d’unes terres a Barcelona propietat de jueus (1000)",
    subtitol: "Els efectes de l’atac d’Al-Mansur",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_007_01.png",
            "miniatures/LQ/LQ_007_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_007_01.png",
        "miniatures/HQ/HQ_007_02.png"
    ],
    miniatura: "miniatures/TL/TL_007.png",
    imatge3D: "miniatures/007/007.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "008",
    data: "1020",
    titol: "Epístola mortuòria en record del comte Bernat Tallaferro (1020)",
    subtitol: "“Era per a nosaltres Príncep i Pare de la pàtria”",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_008_01.png",
            "miniatures/LQ/LQ_008_02.png",
            "miniatures/LQ/LQ_008_03.png",
            "miniatures/LQ/LQ_008_04.png",
            "miniatures/LQ/LQ_008_05.png",
            "miniatures/LQ/LQ_008_06.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_008_01.png",
        "miniatures/HQ/HQ_008_02.png",
        "miniatures/HQ/HQ_008_03.png",
        "miniatures/HQ/HQ_008_04.png",
        "miniatures/HQ/HQ_008_05.png",
        "miniatures/HQ/HQ_008_06.png"
    ],
    miniatura: "miniatures/TL/TL_008.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "009",
    data: "1023",
    titol: "Carta de l’abat Oliba al rei de Navarra (1023)",
    subtitol: "Les relacions internacionals de l’elit eclesiàstica catalana amb Castella i Navarra",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_009_01.png",
            "miniatures/LQ/LQ_009_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_009_01.png",
        "miniatures/HQ/HQ_009_02.png"
    ],
    miniatura: "miniatures/TL/TL_009.png",
    imatge3D: "miniatures/009/009.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "010",
    data: "[Mitjan segle XI]",
    titol: "Breviarium de Musica (ca. 1040) [fragment] ",
    subtitol: "La producció científica de Ripoll",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_010_01.png",
            "miniatures/LQ/LQ_010_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_010_01.png",
        "miniatures/HQ/HQ_010_02.png"
    ],
    miniatura: "miniatures/TL/TL_010.png",
    imatge3D: "miniatures/010/010.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "011",
    data: "1044",
    titol: "Compravenda al comtat d’Osona (1044)",
    subtitol: "L’escriventa Alba de Vic",autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_011_01.png",
            "miniatures/LQ/LQ_011_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_011_01.png",
        "miniatures/HQ/HQ_011_02.png"
    ],
    miniatura: "miniatures/TL/TL_011.png",
    imatge3D: "miniatures/011/011.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "012",
    data: "[ca. 1045]",
    titol: "Jurament de fidelitat de Radulf Oriol als comtes de Pallars (ca.1045)",
    subtitol: "El primer text en llengua catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_012_01.png",
            "miniatures/LQ/LQ_012_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_012_01.png",
        "miniatures/HQ/HQ_012_02.png"
    ],
    miniatura: "miniatures/TL/TL_012.png",
    imatge3D: "miniatures/012/012.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "013",
    data: "1048",
    titol: "Donació del comte Ermengol a Santa Maria de la Seu (1048)",
    subtitol: "La multiplicació de mercats i fires",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_013_01.png",
            "miniatures/LQ/LQ_013_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_013_01.png",
        "miniatures/HQ/HQ_013_02.png"
    ],
    miniatura: "miniatures/TL/TL_013.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "014",
    data: "[Mitjan segle XI]",
    titol: "Tractat sobre l’astrolabi (mitjan segle XI) [fragments]",
    subtitol: "Gerbert d’Orlhac i el desenvolupament de l’astronomia a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_014_01.png",
            "miniatures/LQ/LQ_014_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_014_01.png",
        "miniatures/HQ/HQ_014_02.png"
    ],
    miniatura: "miniatures/TL/TL_014.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "015",
    data: "[ca. 1058]",
    titol: "Contracte per a l’encunyació de moneda d’argent a Barcelona (1058)",
    subtitol: "La potestat d’emetre moneda",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_015_01.png",
            "miniatures/LQ/LQ_015_02.png"
        ],
        imatgesHQ: [
            "miniatures/HQ/HQ_015_01.png",
            "miniatures/HQ/HQ_015_02.png"
        ],
    miniatura: "miniatures/TL/TL_015.png",
    imatge3D: "miniatures/015/015.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "016",
    data: "1060-1080",
    titol: "Fragment del Llibre dels Jutges (1060-1080)",
    subtitol: "El primer text literari en llengua catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_016_01.png",
            "miniatures/LQ/LQ_016_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_016_01.png",
        "miniatures/HQ/HQ_016_02.png"
    ],
    miniatura: "miniatures/TL/TL_016.png",
    imatge3D: "miniatures/016/016.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "017",
    data: "1091",
    titol: "Butlla de restauració de la Seu metropolitana de Tarragona (1091)",
    subtitol: "La creació d’un arquebisbat propi a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_017_01.png",
            "miniatures/LQ/LQ_017_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_017_01.png",
        "miniatures/HQ/HQ_017_02.png"
    ],
    miniatura: "miniatures/TL/TL_017.png",
    imatge3D: "miniatures/017/017.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "018",
    data: "1105",
    titol: "Greuges de Guitard Isarn, senyor de Caboet (1105)",
    subtitol: "Ramón de Cabó, el primer autor conegut en llengua catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_018_01.png",
            "miniatures/LQ/LQ_018_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_018_01.png",
        "miniatures/HQ/HQ_018_02.png"
    ],
    miniatura: "miniatures/TL/TL_018.png",
    imatge3D: "miniatures/018/018.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "019",
    data: "1111",
    titol: "Esponsalici de Guadall i Elvira (1111)",
    subtitol: "El dret matrimonial català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_019_01.png",
            "miniatures/LQ/LQ_019_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_019_01.png",
        "miniatures/HQ/HQ_019_02.png"
    ],
    miniatura: "miniatures/TL/TL_019.png",
    imatge3D: "miniatures/019/019.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "020",
    data: "1113",
    titol: "Compra del comtat de Provença pel comte de Barcelona (1113)",
    subtitol: "L’expansió catalana al nord de les Corberes",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_020_01.png",
            "miniatures/LQ/LQ_020_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_020_01.png",
        "miniatures/HQ/HQ_020_02.png"
    ],
    miniatura: "miniatures/TL/TL_020.png",
    imatge3D: "miniatures/020/020.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "021",
    data: "[ca. 1117]",
    titol: "Liber Maiolichinus (1115-1120) [fragment]",
    subtitol: "La primera plasmació escrita de “Catalunya” i dels “Catalans”",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_021_01.png",
            "miniatures/LQ/LQ_021_02.png",
            "miniatures/LQ/LQ_021_03.png",
            "miniatures/LQ/LQ_021_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_021_01.png",
        "miniatures/HQ/HQ_021_02.png",
        "miniatures/HQ/HQ_021_03.png",
        "miniatures/HQ/HQ_021_04.png"
    ],
    miniatura: "miniatures/TL/TL_021.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "022",
    data: "1128",
    titol: "Aliança amb el ducat de Pulla i Calàbria (1128)",
    subtitol: "Les relacions catalano-normandes al Mediterrani",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_022_01.png",
            "miniatures/LQ/LQ_022_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_022_01.png",
        "miniatures/HQ/HQ_022_02.png"
    ],
    miniatura: "miniatures/TL/TL_022.png",
    imatge3D: "miniatures/022/022.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "023",
    data: "1131",
    titol: "Entrada de Ramon Berenguer III a l’orde del Temple (1131)",
    subtitol: "Les ordes militars a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_023_01.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_023_01.png"
    ],
    miniatura: "miniatures/TL/TL_023.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "024",
    data: "1137",
    titol: "Capítols matrimonials de Ramon Berenguer IV i Peronella (1137)",
    subtitol: "El naixement de la Corona d’Aragó",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_024_01.png",
            "miniatures/LQ/LQ_024_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_024_01.png",
        "miniatures/HQ/HQ_024_02.png"
    ],
    miniatura: "miniatures/TL/TL_024.png",
    imatge3D: "miniatures/024/024.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "025",
    data: "1148",
    titol: "Capitulació amb els sarraïns de Tortosa (1148)",
    subtitol: "La conquesta de la Catalunya Nova: la població sarraïna",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_025_01.png",
            "miniatures/LQ/LQ_025_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_025_01.png",
        "miniatures/HQ/HQ_025_02.png"
    ],
    miniatura: "miniatures/TL/TL_025.png",
    imatge3D: "miniatures/025/025.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "026",
    data: "1149",
    titol: "Capitulació amb els jueus de Tortosa (1149)",
    subtitol: "La conquesta de la Catalunya Nova: la població jueva",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_026_01.png",
            "miniatures/LQ/LQ_026_02.png",
            "miniatures/LQ/LQ_026_03.png",
            "miniatures/LQ/LQ_026_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_026_01.png",
        "miniatures/HQ/HQ_026_02.png",
        "miniatures/HQ/HQ_026_03.png",
        "miniatures/HQ/HQ_026_04.png"
    ],
    miniatura: "miniatures/TL/TL_026.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "027",
    data: "1150",
    titol: "Carta de poblament de Lleida (1150)",
    subtitol: "L’organització política de la Catalunya Nova",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_027_01.png",
            "miniatures/LQ/LQ_027_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_027_01.png",
        "miniatures/HQ/HQ_027_02.png"
    ],
    miniatura: "miniatures/TL/TL_027.png",
    imatge3D: "miniatures/027/027.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "028",
    data: "[ca. 1140-1150]",
    titol: "Usatges de Barcelona (1140-1150)",
    subtitol: "El nou codi jurídic de la Catalunya feudal",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_028_01.png",
            "miniatures/LQ/LQ_028_02.png",
            "miniatures/LQ/LQ_028_03.png",
            "miniatures/LQ/LQ_028_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_028_01.png",
        "miniatures/HQ/HQ_028_02.png",
        "miniatures/HQ/HQ_028_03.png",
        "miniatures/HQ/HQ_028_04.png"
    ],
    miniatura: "miniatures/TL/TL_028.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "029",
    data: "115[1]",
    titol: "Fundació del monestir de Poblet (115[1])",
    subtitol: "L’entrada de l’orde del Cistell a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_029_01.png",
            "miniatures/LQ/LQ_029_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_029_01.png",
        "miniatures/HQ/HQ_029_02.png"
    ],
    miniatura: "miniatures/TL/TL_029.png",
    imatge3D: "miniatures/029/029.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "030",
    data: "1173",
    titol: "Assemblea de Pau i Treva de Fondarella (1173)",
    subtitol: "La Pau del Príncep",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_030_01.png",
            "miniatures/LQ/LQ_030_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_030_01.png",
        "miniatures/HQ/HQ_030_02.png"
    ],    
    miniatura: "miniatures/TL/TL_030.png",
    imatge3D: "miniatures/030/030.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "031",
    data: "1192-1196",
    titol: "Liber Feudorum Maior (1178-1196) [fragment] ",
    subtitol: "L’administració del patrimoni reial",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_031_01.png",
            "miniatures/LQ/LQ_031_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_031_01.png",
        "miniatures/HQ/HQ_031_02.png"
    ], 
    miniatura: "miniatures/TL/TL_031.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "032",
    data: "1214",
    titol: "Llista d’assistents a les Corts de Lleida (1214)",
    subtitol: "Els orígens de les Corts Catalanes",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_032_01.png",
            "miniatures/LQ/LQ_032_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_032_01.png",
        "miniatures/HQ/HQ_032_02.png"
    ], 
    miniatura: "miniatures/TL/TL_032.png",
    imatge3D: "miniatures/032/032.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "033",
    data: "1221",
    titol: "Llibre notarial de Vic (1221) [fragment]",
    subtitol: "El protocol notarial més antic conservat a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_033_01.png",
            "miniatures/LQ/LQ_033_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_033_01.png",
        "miniatures/HQ/HQ_033_02.png"
    ], 
    miniatura: "miniatures/TL/TL_033.png",
    imatge3D: "miniatures/033/033.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "034",
    data: "[ca. 1232]",
    titol: "Llibre del repartiment de Mallorca (ca.1232) [fragment] ",
    subtitol: "La creació i el repoblament del Regne de Mallorques",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_034_01.png",
            "miniatures/LQ/LQ_034_02.png",
            "miniatures/LQ/LQ_034_03.png",
            "miniatures/LQ/LQ_034_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_034_01.png",
        "miniatures/HQ/HQ_034_02.png",
        "miniatures/HQ/HQ_034_03.png",
        "miniatures/HQ/HQ_034_04.png"
    ],
    miniatura: "miniatures/TL/TL_034.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "035",
    data: "[ca. 1235-1236]",
    titol: "Summa de matrimonio (1235-1236) [fragment]",
    subtitol: "Ramon de Penyafort, pare del dret canònic medieval europeu",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_035_01.png",
            "miniatures/LQ/LQ_035_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_035_01.png",
        "miniatures/HQ/HQ_035_02.png"
    ],
    miniatura: "miniatures/TL/TL_035.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "036",
    data: "1244",
    titol: "Definició dels límits territorials de Catalunya i Aragó per Jaume I (1244)",
    subtitol: "“Des del Cinca a Salses”",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_036_01.png",
            "miniatures/LQ/LQ_036_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_036_01.png",
        "miniatures/HQ/HQ_036_02.png"
    ],
    miniatura: "miniatures/TL/TL_036.png",
    imatge3D: "miniatures/036/036.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "037",
    data: "1237-1245",
    titol: "Llibre del repartiment de València (1237-1254) [fragment] ",
    subtitol: "La creació i el repoblament del Regne de València",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_037_01.png",
            "miniatures/LQ/LQ_037_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_037_01.png",
        "miniatures/HQ/HQ_037_02.png"
    ],
    miniatura: "miniatures/TL/TL_037.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "038",
    data: "1258",
    titol: "Tractat de Corbeil (1258)",
    subtitol: "L’aliança amb França i la fi del somni occità",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_038_01.png",
            "miniatures/LQ/LQ_038_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_038_01.png",
        "miniatures/HQ/HQ_038_02.png"
    ],
    miniatura: "miniatures/TL/TL_038.png",
    imatge3D: "miniatures/038/038.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "039",
    data: "1265",
    titol: "Reforma del Consell de Cent (1265)",
    subtitol: "El poder municipal de l’oligarquia barcelonina",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_039_01.png",
            "miniatures/LQ/LQ_039_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_039_01.png",
        "miniatures/HQ/HQ_039_02.png"
    ],
    miniatura: "miniatures/TL/TL_039.png",
    imatge3D: "miniatures/039/039.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "040",
    data: "1266",
    titol: "Privilegi de nomenament de cònsols d’ultramar (1266)",
    subtitol: "Els consolats catalans al Mediterrani",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_040_01.png",
            "miniatures/LQ/LQ_040_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_040_01.png",
        "miniatures/HQ/HQ_040_02.png"
    ],
    miniatura: "miniatures/TL/TL_040.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "041",
    data: "1269",
    titol: "Fundació de la confraria de Tàrrega (1269)",
    subtitol: "El món gremial català",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_041_01.png",
            "miniatures/LQ/LQ_041_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_041_01.png",
        "miniatures/HQ/HQ_041_02.png"
    ],
    miniatura: "miniatures/TL/TL_041.png",
    imatge3D: "miniatures/041/041.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "042",
    data: "1180 - 1270]",
    imatge: "miniatures/LQ/LQ_042",
    titol: "Gesta Comitum Barchinonensium (1180-1270) [fragment] ",
    subtitol: "La primera historiografia catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_042_01.png",
            "miniatures/LQ/LQ_042_02.png"
        ],
    miniatura: "miniatures/TL/TL_042.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "043",
    data: "[Posterior a 1270]",
    imatge: "miniatures/LQ/LQ_043",
    titol: "Libre dels feyts de Jaume I (post.1270) [fragment] ",
    subtitol: "La primera gran crònica catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_043_01.png",
            "miniatures/LQ/LQ_043_02.png"
        ],
    miniatura: "miniatures/TL/TL_043.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "044",
    data: "[ca. 1272-1277]",
    titol: "Costums de Tortosa (1272-post.1277) [fragment]",
    subtitol: "La primera font jurídica escrita en català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_044_01.png",
            "miniatures/LQ/LQ_044_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_044_01.png",
        "miniatures/HQ/HQ_044_02.png"
    ],
    miniatura: "miniatures/TL/TL_044.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "045",
    data: "1283",
    titol: "Excomunicació de Pere el Gran (1283)",
    subtitol: "Les conseqüències immediates de la conquesta catalana de Sicília",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_045_01.png",
            "miniatures/LQ/LQ_045_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_045_01.png",
        "miniatures/HQ/HQ_045_02.png"
    ],
    miniatura: "miniatures/TL/TL_045.png",
    imatge3D: "miniatures/045/045.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "046",
    data: "1284",
    titol: "Recognoverunt proceres (1284)",
    subtitol: "El dret municipal català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_046_01.png",
            "miniatures/LQ/LQ_046_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_046_01.png",
        "miniatures/HQ/HQ_046_02.png"
    ],
    miniatura: "miniatures/TL/TL_046.png",
    imatge3D: "miniatures/046/046.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "047",
    data: "ca. 1285",
    titol: "Pastorel·la de Cerverí de Girona (1285)",
    subtitol: "La cultura trobadoresca a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_047_01.png",
            "miniatures/LQ/LQ_047_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_047_01.png",
        "miniatures/HQ/HQ_047_02.png"
    ],
    miniatura: "miniatures/TL/TL_047.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "048",
    data: "ca. 1283-1295",
    titol: "Crònica de Bernat Desclot (1286-1288) [fragment] ",
    subtitol: "Un historiador a la cort del rei Pere el Gran",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_048_01.png",
            "miniatures/LQ/LQ_048_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_048_01.png",
        "miniatures/HQ/HQ_048_02.png"
    ],
    miniatura: "miniatures/TL/TL_048.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "049",
    data: "1289",
    titol: "Inventari dels béns de les comandes templeres (1289) [fragment] ",
    subtitol: "L’orde del Temple a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_049_01.png",
            "miniatures/LQ/LQ_049_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_049_01.png",
        "miniatures/HQ/HQ_049_02.png"
    ],
    miniatura: "miniatures/TL/TL_049.png",
    imatge3D: "miniatures/049/049.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "050",
    data: "1297",
    titol: "Contracte amb una societat comercial barcelonina (1297)",
    subtitol: "L’eclosió del comerç català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_050_01.png",
            "miniatures/LQ/LQ_050_02.png",
            "miniatures/LQ/LQ_050_03.png",
            "miniatures/LQ/LQ_050_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_050_01.png",
        "miniatures/HQ/HQ_050_02.png",
        "miniatures/HQ/HQ_050_03.png",
        "miniatures/HQ/HQ_050_04.png"
    ],
    miniatura: "miniatures/TL/TL_050.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "051",
    data: "1300",
    titol: "Creació de l’Estudi General de Lleida (1300)",
    subtitol: "La primera universitat catalana",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_051_01.png",
            "miniatures/LQ/LQ_051_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_051_01.png",
        "miniatures/HQ/HQ_051_02.png"
    ],
    miniatura: "miniatures/TL/TL_051.png",
    imatge3D: "miniatures/051/051.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "052",
    data: "1304",
    titol: "Carta del batlle de Barcelona sobre la manufactura tèxtil (1304)",
    subtitol: "El desenvolupament del sector draper a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_052_01.png",
            "miniatures/LQ/LQ_052_01.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_052_01.png",
        "miniatures/HQ/HQ_052_02.png"
    ],
    miniatura: "miniatures/TL/TL_052.png",
    imatge3D: "miniatures/052/052.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "053",
    data: "1315",
    titol: "Impost per finançar una armada contra els sarraïns (1315)",
    subtitol: "Les rutes marítimes i la lluita contra la pirateria",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_053_01.png",
            "miniatures/LQ/LQ_053_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_053_01.png",
        "miniatures/HQ/HQ_053_02.png"
    ],
    miniatura: "miniatures/TL/TL_053.png",
    imatge3D: "miniatures/053/053.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "054",
    data: "1319",
    titol: "Rúbrica comptable d’unes obres al Palau Reial (1319)",
    subtitol: "La creació de l’Arxiu Reial de Barcelona",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_054_01.png",
            "miniatures/LQ/LQ_054_02.png",
            "miniatures/LQ/LQ_054_03.png",
            "miniatures/LQ/LQ_054_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_054_01.png",
        "miniatures/HQ/HQ_054_02.png",
        "miniatures/HQ/HQ_054_03.png",
        "miniatures/HQ/HQ_054_04.png"
    ],
    miniatura: "miniatures/TL/TL_054.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "055",
    data: "ca. 1325-1328",
    titol: "Llibre de Ramon Muntaner (1325-1328) [fragment] ",
    subtitol: "La biografia novel·lada d’un almogàver",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_055_01.png",
            "miniatures/LQ/LQ_055_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_055_01.png",
        "miniatures/HQ/HQ_055_02.png"
    ],
    miniatura: "miniatures/TL/TL_055.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "056",
    data: "1334",
    titol: "Ordre reial per a regular el preu del gra (1334)",
    subtitol: "Els efectes de “lo mal any primer”",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_056_01.png",
            "miniatures/LQ/LQ_056_02.png",
            "miniatures/LQ/LQ_056_03.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_056_01.png",
        "miniatures/HQ/HQ_056_02.png",
        "miniatures/HQ/HQ_056_03.png"
    ],
    miniatura: "miniatures/TL/TL_056.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "057",
    data: "1348",
    titol: "Regiment de pestilència de Jacme d’Agramont (1348)",
    subtitol: "El primer tractat europeu sobre la pesta",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_057_01.png","miniatures/LQ/LQ_057_02.png","miniatures/LQ/LQ_057_03.png",
            "miniatures/LQ/LQ_057_04.png","miniatures/LQ/LQ_057_05.png","miniatures/LQ/LQ_057_06.png",
            "miniatures/LQ/LQ_057_07.png","miniatures/LQ/LQ_057_08.png","miniatures/LQ/LQ_057_09.png",
            "miniatures/LQ/LQ_057_10.png","miniatures/LQ/LQ_057_11.png","miniatures/LQ/LQ_057_12.png",
            "miniatures/LQ/LQ_057_13.png","miniatures/LQ/LQ_057_14.png","miniatures/LQ/LQ_057_15.png",
            "miniatures/LQ/LQ_057_16.png","miniatures/LQ/LQ_057_17.png","miniatures/LQ/LQ_057_18.png",
            "miniatures/LQ/LQ_057_19.png","miniatures/LQ/LQ_057_20.png","miniatures/LQ/LQ_057_21.png",
            "miniatures/LQ/LQ_057_22.png","miniatures/LQ/LQ_057_23.png","miniatures/LQ/LQ_057_24.png",
            "miniatures/LQ/LQ_057_25.png","miniatures/LQ/LQ_057_26.png","miniatures/LQ/LQ_057_27.png",
            "miniatures/LQ/LQ_057_28.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_057_01.png","miniatures/HQ/HQ_057_02.png","miniatures/HQ/HQ_057_03.png",
        "miniatures/HQ/HQ_057_04.png","miniatures/HQ/HQ_057_05.png","miniatures/HQ/HQ_057_06.png",
        "miniatures/HQ/HQ_057_07.png","miniatures/HQ/HQ_057_08.png","miniatures/HQ/HQ_057_09.png",
        "miniatures/HQ/HQ_057_10.png","miniatures/HQ/HQ_057_11.png","miniatures/HQ/HQ_057_12.png",
        "miniatures/HQ/HQ_057_13.png","miniatures/HQ/HQ_057_14.png","miniatures/HQ/HQ_057_15.png",
        "miniatures/HQ/HQ_057_16.png","miniatures/HQ/HQ_057_17.png","miniatures/HQ/HQ_057_18.png",
        "miniatures/HQ/HQ_057_19.png","miniatures/HQ/HQ_057_20.png","miniatures/HQ/HQ_057_21.png",
        "miniatures/HQ/HQ_057_22.png","miniatures/HQ/HQ_057_23.png","miniatures/HQ/HQ_057_24.png",
        "miniatures/HQ/HQ_057_25.png","miniatures/HQ/HQ_057_26.png","miniatures/HQ/HQ_057_27.png",
        "miniatures/HQ/HQ_057_28.png"
    ],
    miniatura: "miniatures/TL/TL_057.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "058",
    data: "1350",
    titol: "Carta del soldà de Tunis (1350)",
    subtitol: "Les relacions diplomàtiques amb el món àrab",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_058_01.png",
            "miniatures/LQ/LQ_058_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_058_01.png",
        "miniatures/HQ/HQ_058_02.png"
    ],  
    miniatura: "miniatures/TL/TL_058.png",
    imatge3D: "miniatures/058/058.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "059",
    data: "[Primera meitat del segle XIV]",
    titol: "Hagadà “germana” (primera meitat del segle XIV) [fragment] ",
    subtitol: "La poderosa comunitat jueva de Barcelona",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_059_01.png",
            "miniatures/LQ/LQ_059_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_059_01.png",
        "miniatures/HQ/HQ_059_02.png"
    ],  
    miniatura: "miniatures/TL/TL_059.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "060",
    data: "1359",
    titol: "Capítols signats a les Corts de Cervera (1359)",
    subtitol: "El naixement de la Generalitat",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_060_01.png","miniatures/LQ/LQ_060_02.png",
            "miniatures/LQ/LQ_060_03.png","miniatures/LQ/LQ_060_04.png",
            "miniatures/LQ/LQ_060_05.png","miniatures/LQ/LQ_060_06.png",
            "miniatures/LQ/LQ_060_07.png","miniatures/LQ/LQ_060_08.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_060_01.png","miniatures/HQ/HQ_060_02.png","miniatures/HQ/HQ_060_03.png",
        "miniatures/HQ/HQ_060_04.png","miniatures/HQ/HQ_060_05.png","miniatures/HQ/HQ_060_06.png",
        "miniatures/HQ/HQ_060_07.png","miniatures/HQ/HQ_060_08.png"
    ],
    miniatura: "miniatures/TL/TL_060.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "061",
    data: "[ca. 1375-1383]",
    titol: "Crònica de Pere el Cerimoniós (1375-1383)",
    subtitol: "La darrera gran crònica catalana, justificació de l’autoritarisme real",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_061_01.png",
            "miniatures/LQ/LQ_061_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_061_01.png",
        "miniatures/HQ/HQ_061_02.png"
    ], 
    miniatura: "miniatures/TL/TL_061.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "062",
    data: "s.XIV",
    imatge: "miniatures/LQ/LQ_062",
    titol: "Llibre del Consolat de Mar (segle XIV)",
    subtitol: "El codi marítim de referència al Mediterrani",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_062_01.png"
        ],
    miniatura: "miniatures/TL/TL_062.png",
    imatge3D: "miniatures/062/062.gltf",
    tresD: true,
    HD: false,
    ePub: false,
    pdf: false
    },
    {id: "063",
    data: "[1405]",
    titol: "Ordinacions de la Taula de Canvi de Barcelona (1401-1405)",
    subtitol: "La primera banca pública d’Europa",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_063_01.png","miniatures/LQ/LQ_063_02.png","miniatures/LQ/LQ_063_03.png",
            "miniatures/LQ/LQ_063_04.png","miniatures/LQ/LQ_063_05.png","miniatures/LQ/LQ_063_06.png",
            "miniatures/LQ/LQ_063_07.png","miniatures/LQ/LQ_063_08.png","miniatures/LQ/LQ_063_09.png",
            "miniatures/LQ/LQ_063_10.png","miniatures/LQ/LQ_063_11.png","miniatures/LQ/LQ_063_12.png",
            "miniatures/LQ/LQ_063_13.png","miniatures/LQ/LQ_063_14.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_063_01.png","miniatures/HQ/HQ_063_02.png","miniatures/HQ/HQ_063_03.png",
        "miniatures/HQ/HQ_063_04.png","miniatures/HQ/HQ_063_05.png","miniatures/HQ/HQ_063_06.png",
        "miniatures/HQ/HQ_063_07.png","miniatures/HQ/HQ_063_08.png","miniatures/HQ/HQ_063_09.png",
        "miniatures/HQ/HQ_063_10.png","miniatures/HQ/HQ_063_11.png","miniatures/HQ/HQ_063_12.png",
        "miniatures/HQ/HQ_063_13.png","miniatures/HQ/HQ_063_14.png"
    ],
    miniatura: "miniatures/TL/TL_063.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "064",
    data: "1406",
    titol: "Proposició a Corts de Martí l’Humà (1406)",
    subtitol: "Una glossa reial a la glòria de Catalunya i dels catalans",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_064_01.png",
            "miniatures/LQ/LQ_064_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_064_01.png",
        "miniatures/HQ/HQ_064_02.png"
    ], 
    miniatura: "miniatures/TL/TL_064.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "065",
    data: "1412",
    titol: "Acta del compromís de Casp (1412)",
    subtitol: "L’adveniment del llinatge Trastàmara",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_065_01.png",
            "miniatures/LQ/LQ_065_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_065_01.png",
        "miniatures/HQ/HQ_065_02.png"
    ], 
    miniatura: "miniatures/TL/TL_065.png",
    imatge3D: "miniatures/065/065.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "066",
    data: "1416-1417",
    titol: "Consulta a mestres d'obra sobre la continuació de la catedral de Girona (1416)",
    subtitol: "L’arquitectura gòtica catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_066_01.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_066_01.png"
    ], 
    miniatura: "miniatures/TL/TL_066.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "067",
    data: "1418",
    titol: "Carta de Margarida de Prades (ca.1418)",
    subtitol: "L’única carta autògrafa d’una reina catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_067_01.png"
        ],
    miniatura: "miniatures/TL/TL_067.png",
    imatge3D: "miniatures/067/067.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "068",
    data: "1442",
    titol: "Carta d’Alfons el Magnànim a Maria de Castella (1442)",
    subtitol: "La conquesta catalana de Nàpols",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_068_01.png",
            "miniatures/LQ/LQ_068_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_068_01.png",
        "miniatures/HQ/HQ_068_02.png"
    ], 
    miniatura: "miniatures/TL/TL_068.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "069",
    data: "1448",
    titol: "Comentari als Usatges de Jaume Marquilles (1448)",
    subtitol: "L’ordenament jurídic català: entre el dret comú i les lleis de la terra",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_069_01.png",
            "miniatures/LQ/LQ_069_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_069_01.png",
        "miniatures/HQ/HQ_069_02.png"
    ], 
    miniatura: "miniatures/TL/TL_069.png",
    imatge3D: "miniatures/069/069.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "070",
    data: "1448-1449",
    titol: "Llibre del Sindicat Remença (1448-1449) [fragment] ",
    subtitol: "El primer sindicat camperol d’Europa",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_070_01.png",
            "miniatures/LQ/LQ_070_02.png",
            "miniatures/LQ/LQ_070_03.png",
            "miniatures/LQ/LQ_070_04.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_070_01.png",
        "miniatures/HQ/HQ_070_02.png",
        "miniatures/HQ/HQ_070_03.png",
        "miniatures/HQ/HQ_070_04.png"
    ], 
    miniatura: "miniatures/TL/TL_070.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "071",
    data: "1455",
    titol: "Estatuts de la confraria d’esclaus i lliberts negres de Sant Jaume de Barcelona (1455)",
    subtitol: "La primera confraria negra de l’Occident cristià",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_071_01.png","miniatures/LQ/LQ_071_02.png",
            "miniatures/LQ/LQ_071_03.png","miniatures/LQ/LQ_071_04.png",
            "miniatures/LQ/LQ_071_05.png","miniatures/LQ/LQ_071_06.png",
            "miniatures/LQ/LQ_071_07.png","miniatures/LQ/LQ_071_08.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_071_01.png","miniatures/HQ/HQ_071_02.png","miniatures/HQ/HQ_071_03.png",
        "miniatures/HQ/HQ_071_04.png","miniatures/HQ/HQ_071_05.png","miniatures/HQ/HQ_071_06.png",
        "miniatures/HQ/HQ_071_07.png","miniatures/HQ/HQ_071_08.png"
    ],
    miniatura: "miniatures/TL/TL_071.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "072",
    data: "1456",
    titol: "Portolà de Jaume Bertran i Berenguer Ripoll (1456)",
    subtitol: "La cartografia nàutica medieval a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_072_01.png"
        ],
    miniatura: "miniatures/TL/TL_072.png",
    imatge3D: "",
    tresD: false,
    HD: false,
    ePub: false,
    pdf: false
    },
    {id: "073",
    data: "1472",
    imatge: "miniatures/LQ/LQ_073",
    titol: "Capitulació de Pedralbes (1472)",
    subtitol: "La guerra civil catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_073_01.png","miniatures/LQ/LQ_073_02.png","miniatures/LQ/LQ_073_03.png",
            "miniatures/LQ/LQ_073_04.png","miniatures/LQ/LQ_073_05.png","miniatures/LQ/LQ_073_06.png",
            "miniatures/LQ/LQ_073_07.png","miniatures/LQ/LQ_073_08.png","miniatures/LQ/LQ_073_09.png",
            "miniatures/LQ/LQ_073_10.png","miniatures/LQ/LQ_073_11.png","miniatures/LQ/LQ_073_12.png",
            "miniatures/LQ/LQ_073_13.png","miniatures/LQ/LQ_073_14.png","miniatures/LQ/LQ_073_15.png",
            "miniatures/LQ/LQ_073_16.png","miniatures/LQ/LQ_073_17.png","miniatures/LQ/LQ_073_18.png",
            "miniatures/LQ/LQ_073_19.png","miniatures/LQ/LQ_073_20.png","miniatures/LQ/LQ_073_21.png",
            "miniatures/LQ/LQ_073_22.png","miniatures/LQ/LQ_073_23.png","miniatures/LQ/LQ_073_24.png",
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_073_01.png","miniatures/HQ/HQ_073_02.png","miniatures/HQ/HQ_073_03.png",
        "miniatures/HQ/HQ_073_04.png","miniatures/HQ/HQ_073_05.png","miniatures/HQ/HQ_073_06.png",
        "miniatures/HQ/HQ_073_07.png","miniatures/HQ/HQ_073_08.png","miniatures/HQ/HQ_073_09.png",
        "miniatures/HQ/HQ_073_10.png","miniatures/HQ/HQ_073_11.png","miniatures/HQ/HQ_073_12.png",
        "miniatures/HQ/HQ_073_13.png","miniatures/HQ/HQ_073_14.png","miniatures/HQ/HQ_073_15.png",
        "miniatures/HQ/HQ_073_16.png","miniatures/HQ/HQ_073_17.png","miniatures/HQ/HQ_073_18.png",
        "miniatures/HQ/HQ_073_19.png","miniatures/HQ/HQ_073_20.png","miniatures/HQ/HQ_073_21.png",
        "miniatures/HQ/HQ_073_22.png","miniatures/HQ/HQ_073_23.png","miniatures/HQ/HQ_073_24.png"
    ],
    miniatura: "miniatures/TL/TL_073.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "074",
    data: "1480, 1481, 1495",
    titol: "Constitució de l’Observança (1481)",
    subtitol: "La subjecció reial a les lleis de la terra",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_074_01.png",
            "miniatures/LQ/LQ_074_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_074_01.png",
        "miniatures/HQ/HQ_074_02.png"
    ], 
    miniatura: "miniatures/TL/TL_074.png",
    imatge3D: "miniatures/074/074.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "075",
    data: "1486",
    titol: "Sentència arbitral de Guadalupe (1486)",
    subtitol: "Les conseqüències de les guerres remences",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_075_01.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_075_01.png"
    ], 
    miniatura: "miniatures/TL/TL_075.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "076",
    data: "1493",
    titol: "Creació de la Reial Audiència de Catalunya (1493)",
    subtitol: "El Tribunal Suprem català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_076_01.png","miniatures/LQ/LQ_076_02.png","miniatures/LQ/LQ_076_03.png",
            "miniatures/LQ/LQ_076_04.png","miniatures/LQ/LQ_076_05.png","miniatures/LQ/LQ_076_06.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_076_01.png","miniatures/HQ/HQ_076_02.png","miniatures/HQ/HQ_076_03.png",
        "miniatures/HQ/HQ_076_04.png","miniatures/HQ/HQ_076_05.png","miniatures/HQ/HQ_076_06.png"
    ],
    miniatura: "miniatures/TL/TL_076.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "077",
    data: "1498",
    titol: "Privilegi de la insaculació (1498)",
    subtitol: "L’accés al Consell de Cent",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_077_01.png",
            "miniatures/LQ/LQ_077_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_077_01.png",
        "miniatures/HQ/HQ_077_02.png"
    ],
    miniatura: "miniatures/TL/TL_077.png",
    imatge3D: "miniatures/077/077.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "078",
    data: "1516",
    titol: "Carta de la reina Germana de Foix als diputats de la Generalitat (1516)",
    subtitol: "La successió de Ferran II el Catòlic",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_078_01.png",
            "miniatures/LQ/LQ_078_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_078_01.png",
        "miniatures/HQ/HQ_078_02.png"
    ],
    miniatura: "miniatures/TL/TL_078.png",
    imatge3D: "miniatures/078/078.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "079",
    data: "1549",
    titol: "Cobles sobre la mort d’en Malet (1549)",
    subtitol: "La cacera de bruixes a Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_079_01.png",
            "miniatures/LQ/LQ_079_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_079_01.png",
        "miniatures/HQ/HQ_079_02.png"
    ],
    miniatura: "miniatures/TL/TL_079.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "080",
    data: "1557",
    titol: "Els col·loquis de la insigne ciutat de Tortosa (1557)",
    subtitol: "Un diàleg renaixentista",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_080_01.png","miniatures/LQ/LQ_080_02.png","miniatures/LQ/LQ_080_03.png",
            "miniatures/LQ/LQ_080_04.png","miniatures/LQ/LQ_080_05.png","miniatures/LQ/LQ_080_06.png",
            "miniatures/LQ/LQ_080_07.png","miniatures/LQ/LQ_080_08.png","miniatures/LQ/LQ_080_09.png",
            "miniatures/LQ/LQ_080_10.png","miniatures/LQ/LQ_080_11.png","miniatures/LQ/LQ_080_12.png",
            "miniatures/LQ/LQ_080_13.png","miniatures/LQ/LQ_080_14.png","miniatures/LQ/LQ_080_15.png",
            "miniatures/LQ/LQ_080_16.png","miniatures/LQ/LQ_080_17.png","miniatures/LQ/LQ_080_18.png",
            "miniatures/LQ/LQ_080_19.png","miniatures/LQ/LQ_080_20.png","miniatures/LQ/LQ_080_21.png",
            "miniatures/LQ/LQ_080_22.png","miniatures/LQ/LQ_080_23.png","miniatures/LQ/LQ_080_24.png",
            "miniatures/LQ/LQ_080_25.png","miniatures/LQ/LQ_080_26.png","miniatures/LQ/LQ_080_27.png",
            "miniatures/LQ/LQ_080_28.png","miniatures/LQ/LQ_080_29.png","miniatures/LQ/LQ_080_30.png",
            "miniatures/LQ/LQ_080_31.png","miniatures/LQ/LQ_080_32.png","miniatures/LQ/LQ_080_33.png",
            "miniatures/LQ/LQ_080_34.png","miniatures/LQ/LQ_080_35.png","miniatures/LQ/LQ_080_36.png",
            "miniatures/LQ/LQ_080_37.png","miniatures/LQ/LQ_080_38.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_080_01.png","miniatures/HQ/HQ_080_02.png","miniatures/HQ/HQ_080_03.png",
        "miniatures/HQ/HQ_080_04.png","miniatures/HQ/HQ_080_05.png","miniatures/HQ/HQ_080_06.png",
        "miniatures/HQ/HQ_080_07.png","miniatures/HQ/HQ_080_08.png","miniatures/HQ/HQ_080_09.png",
        "miniatures/HQ/HQ_080_10.png","miniatures/HQ/HQ_080_11.png","miniatures/HQ/HQ_080_12.png",
        "miniatures/HQ/HQ_080_13.png","miniatures/HQ/HQ_080_14.png","miniatures/HQ/HQ_080_15.png",
        "miniatures/HQ/HQ_080_16.png","miniatures/HQ/HQ_080_17.png","miniatures/HQ/HQ_080_18.png",
        "miniatures/HQ/HQ_080_19.png","miniatures/HQ/HQ_080_20.png","miniatures/HQ/HQ_080_21.png",
        "miniatures/HQ/HQ_080_22.png","miniatures/HQ/HQ_080_23.png","miniatures/HQ/HQ_080_24.png",
        "miniatures/HQ/HQ_080_25.png","miniatures/HQ/HQ_080_26.png","miniatures/HQ/HQ_080_27.png",
        "miniatures/HQ/HQ_080_28.png","miniatures/HQ/HQ_080_29.png","miniatures/HQ/HQ_080_30.png",
        "miniatures/HQ/HQ_080_31.png","miniatures/HQ/HQ_080_32.png","miniatures/HQ/HQ_080_33.png",
        "miniatures/HQ/HQ_080_34.png","miniatures/HQ/HQ_080_35.png","miniatures/HQ/HQ_080_36.png",
        "miniatures/HQ/HQ_080_37.png","miniatures/HQ/HQ_080_38.png"
    ],
    miniatura: "miniatures/TL/TL_080.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "081",
    data: "1568",
    titol: "Nomenament de Lluís de Requesens com a Lloctinent General de la Mar (1568)",
    subtitol: "La noblesa catalana dins la Monarquia Hispànica",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_081_01.png",
            "miniatures/LQ/LQ_081_02.png",
            "miniatures/LQ/LQ_081_03.png",
            "miniatures/LQ/LQ_081_04.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_081_01.png",
        "miniatures/HQ/HQ_081_02.png",
        "miniatures/HQ/HQ_081_03.png",
        "miniatures/HQ/HQ_081_04.png"
    ], 
    miniatura: "miniatures/TL/TL_081.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "082",
    data: "1602A",
    titol: "Nomenament del cònsol dels catalans a Palerm (1602)",
    subtitol: "La vigència dels consolats catalans en època moderna",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_082_01.png",
            "miniatures/LQ/LQ_082_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_082_01.png",
        "miniatures/HQ/HQ_082_02.png"
    ],
    miniatura: "miniatures/TL/TL_082.png",
    imatge3D: "miniatures/082/082.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "083",
    data: "1602B",
    titol: "Llibre Verd IV de Barcelona (1602) [fragment] ",
    subtitol: "Els privilegis municipals",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_083_01.png",
            "miniatures/LQ/LQ_083_02.png"
        ],
    imatgesHQ: [
        "miniatures/HQ/HQ_083_01.png",
        "miniatures/HQ/HQ_083_02.png"
    ],
    miniatura: "miniatures/TL/TL_083.png",
    imatge3D: "miniatures/083/083.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "084",
    data: "1605-1608",
    titol: "Nova Principatus Catalonia Descriptio (1606)",
    subtitol: "El primer mapa de Catalunya",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_084_01.png",
            "miniatures/LQ/LQ_084_02.png"
        ],
        imatgesHQ: [
            "miniatures/HQ/HQ_084_01.png",
            "miniatures/HQ/HQ_084_02.png"
        ],
    miniatura: "miniatures/TL/TL_084.png",
    imatge3D: "miniatures/084/084.gltf",
    tresD: true,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "085",
    data: "1616",
    titol: "Relació de la caiguda de Tallaferro i Trucafort (1616)",
    subtitol: "El bandolerisme català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_085_01.png",
            "miniatures/LQ/LQ_085_02.png",
            "miniatures/LQ/LQ_085_03.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_085_01.png",
        "miniatures/HQ/HQ_085_02.png",
        "miniatures/HQ/HQ_085_03.png"
    ],
    miniatura: "miniatures/TL/TL_085.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "086",
    data: "1641",
    titol: "Proclamació de Lluís XIII com a comte de Barcelona (1641)",
    subtitol: "L’efímera República Catalana",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_086_01.png",
            "miniatures/LQ/LQ_086_02.png",
            "miniatures/LQ/LQ_086_03.png",
            "miniatures/LQ/LQ_086_04.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_086_01.png",
        "miniatures/HQ/HQ_086_02.png",
        "miniatures/HQ/HQ_086_03.png",
        "miniatures/HQ/HQ_086_04.png"
    ],
    miniatura: "miniatures/TL/TL_086.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "087",
    data: "1653",
    titol: "Perdó als catalans concedit per Felip IV (1653)",
    subtitol: "Les conseqüències de la Guerra dels Segadors",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_087_01.png","miniatures/LQ/LQ_087_02.png","miniatures/LQ/LQ_087_03.png",
            "miniatures/LQ/LQ_087_04.png","miniatures/LQ/LQ_087_05.png","miniatures/LQ/LQ_087_06.png",
            "miniatures/LQ/LQ_087_07.png","miniatures/LQ/LQ_087_08.png","miniatures/LQ/LQ_087_09.png",
            "miniatures/LQ/LQ_087_10.png","miniatures/LQ/LQ_087_11.png","miniatures/LQ/LQ_087_12.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_087_01.png","miniatures/HQ/HQ_087_02.png","miniatures/HQ/HQ_087_03.png",
        "miniatures/HQ/HQ_087_04.png","miniatures/HQ/HQ_087_05.png","miniatures/HQ/HQ_087_06.png",
        "miniatures/HQ/HQ_087_07.png","miniatures/HQ/HQ_087_08.png","miniatures/HQ/HQ_087_09.png",
        "miniatures/HQ/HQ_087_10.png","miniatures/HQ/HQ_087_11.png","miniatures/HQ/HQ_087_12.png"
    ],
    miniatura: "miniatures/TL/TL_087.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "088",
    data: "1653B",
    titol: "Privilegi del sisè conseller menestral (1653)",
    subtitol: "La incorporació de nous gremis al Consell de Cent",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_088_01.png","miniatures/LQ/LQ_088_02.png","miniatures/LQ/LQ_088_03.png",
            "miniatures/LQ/LQ_088_04.png","miniatures/LQ/LQ_088_05.png","miniatures/LQ/LQ_088_06.png",
            "miniatures/LQ/LQ_088_07.png","miniatures/LQ/LQ_088_08.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_088_01.png","miniatures/HQ/HQ_088_02.png","miniatures/HQ/HQ_088_03.png",
        "miniatures/HQ/HQ_088_04.png","miniatures/HQ/HQ_088_05.png","miniatures/HQ/HQ_088_06.png",
        "miniatures/HQ/HQ_088_07.png","miniatures/HQ/HQ_088_08.png"
    ],
    miniatura: "miniatures/TL/TL_088.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "089",
    data: "1659",
    titol: "Tractat dels Pirineus (1659)",
    subtitol: "La pèrdua de la Catalunya del nord i la creació de la “frontera” moderna",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_089_01.png","miniatures/LQ/LQ_089_02.png","miniatures/LQ/LQ_089_03.png",
            "miniatures/LQ/LQ_089_04.png","miniatures/LQ/LQ_089_05.png","miniatures/LQ/LQ_089_06.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_089_01.png","miniatures/HQ/HQ_089_02.png","miniatures/HQ/HQ_089_03.png",
        "miniatures/HQ/HQ_089_04.png","miniatures/HQ/HQ_089_05.png","miniatures/HQ/HQ_089_06.png"
    ],
    miniatura: "miniatures/TL/TL_089.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "090",
    data: "1681",
    titol: "Político discurso de Narcís Feliu de la Penya (1681)",
    subtitol: "La defensa del proteccionisme i el redreç econòmic català",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_090_01.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_090_01.png"
    ],
    miniatura: "miniatures/TL/TL_090.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "091",
    data: "1690",
    titol: "Perdó general concedit per Carles II (1690)",
    subtitol: "La revolta dels Barretines",
    autor: "En tal i en tal altre",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_091_01.png","miniatures/LQ/LQ_091_02.png",
            "miniatures/LQ/LQ_091_03.png","miniatures/LQ/LQ_091_04.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_091_01.png","miniatures/HQ/HQ_091_02.png","miniatures/HQ/HQ_091_03.png",
        "miniatures/HQ/HQ_091_04.png"
    ],
    miniatura: "miniatures/TL/TL_091.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "092",
    data: "1691",
    titol: "Ramallet de tintures (1691)",
    subtitol: "El primer llibre català de tintoreria",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_092_01.png","miniatures/LQ/LQ_092_02.png","miniatures/LQ/LQ_092_03.png",
            "miniatures/LQ/LQ_092_04.png","miniatures/LQ/LQ_092_05.png","miniatures/LQ/LQ_092_06.png"
     ],
     imatgesHQ: [
        "miniatures/HQ/HQ_092_01.png","miniatures/HQ/HQ_092_02.png","miniatures/HQ/HQ_092_03.png",
        "miniatures/HQ/HQ_092_04.png","miniatures/HQ/HQ_092_05.png","miniatures/HQ/HQ_092_06.png"
    ],
    miniatura: "miniatures/TL/TL_092.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "093",
    data: "1705",
    titol: "Pacte de Gènova (1705)",
    subtitol: "Un tractat internacional entre Catalunya i Anglaterra",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_093_01.png","miniatures/LQ/LQ_093_02.png","miniatures/LQ/LQ_093_03.png",
            "miniatures/LQ/LQ_093_04.png","miniatures/LQ/LQ_093_05.png","miniatures/LQ/LQ_093_06.png",
            "miniatures/LQ/LQ_093_07.png","miniatures/LQ/LQ_093_08.png","miniatures/LQ/LQ_093_09.png",
            "miniatures/LQ/LQ_093_10.png","miniatures/LQ/LQ_093_11.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_093_01.png","miniatures/HQ/HQ_093_02.png","miniatures/HQ/HQ_093_03.png",
        "miniatures/HQ/HQ_093_04.png","miniatures/HQ/HQ_093_05.png","miniatures/HQ/HQ_093_06.png",
        "miniatures/HQ/HQ_093_07.png","miniatures/HQ/HQ_093_08.png","miniatures/HQ/HQ_093_09.png",
        "miniatures/HQ/HQ_093_10.png","miniatures/HQ/HQ_093_11.png"
    ],
    miniatura: "miniatures/TL/TL_093.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "094",
    data: "1705-1706",
    titol: "Declaració de la successió de la monarquia per les Corts Catalanes (1706)",
    subtitol: "L’exclusió dels Borbons",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_094_01.png","miniatures/LQ/LQ_094_02.png","miniatures/LQ/LQ_094_03.png",
            "miniatures/LQ/LQ_094_04.png","miniatures/LQ/LQ_094_05.png","miniatures/LQ/LQ_094_06.png",
            "miniatures/LQ/LQ_094_07.png","miniatures/LQ/LQ_094_08.png","miniatures/LQ/LQ_094_09.png",
            "miniatures/LQ/LQ_094_10.png","miniatures/LQ/LQ_094_11.png","miniatures/LQ/LQ_094_12.png",
            "miniatures/LQ/LQ_094_13.png","miniatures/LQ/LQ_094_14.png","miniatures/LQ/LQ_094_15.png",
            "miniatures/LQ/LQ_094_16.png","miniatures/LQ/LQ_094_17.png","miniatures/LQ/LQ_094_18.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_094_01.png","miniatures/HQ/HQ_094_02.png","miniatures/HQ/HQ_094_03.png",
        "miniatures/HQ/HQ_094_04.png","miniatures/HQ/HQ_094_05.png","miniatures/HQ/HQ_094_06.png",
        "miniatures/HQ/HQ_094_07.png","miniatures/HQ/HQ_094_08.png","miniatures/HQ/HQ_094_09.png",
        "miniatures/HQ/HQ_094_10.png","miniatures/HQ/HQ_094_11.png","miniatures/HQ/HQ_094_12.png",
        "miniatures/HQ/HQ_094_13.png","miniatures/HQ/HQ_094_14.png","miniatures/HQ/HQ_094_15.png",
        "miniatures/HQ/HQ_094_16.png","miniatures/HQ/HQ_094_17.png","miniatures/HQ/HQ_094_18.png"
    ],
    miniatura: "miniatures/TL/TL_094.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "095",
    data: "1708",
    titol: "Privilegi reial al Consell de Cent de Barcelona (1708)",
    subtitol: "El darrer gran privilegi concedit per l’arxiduc Carles d’Àustria",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_095_01.png","miniatures/LQ/LQ_095_02.png","miniatures/LQ/LQ_095_03.png",
            "miniatures/LQ/LQ_095_04.png","miniatures/LQ/LQ_095_05.png","miniatures/LQ/LQ_095_06.png",
            "miniatures/LQ/LQ_095_07.png","miniatures/LQ/LQ_095_08.png","miniatures/LQ/LQ_095_09.png",
            "miniatures/LQ/LQ_095_10.png","miniatures/LQ/LQ_095_11.png","miniatures/LQ/LQ_095_12.png",
            "miniatures/LQ/LQ_095_13.png","miniatures/LQ/LQ_095_14.png","miniatures/LQ/LQ_095_15.png",
            "miniatures/LQ/LQ_095_16.png","miniatures/LQ/LQ_095_17.png","miniatures/LQ/LQ_095_18.png",
            "miniatures/LQ/LQ_095_19.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_095_01.png","miniatures/HQ/HQ_095_02.png","miniatures/HQ/HQ_095_03.png",
        "miniatures/HQ/HQ_095_04.png","miniatures/HQ/HQ_095_05.png","miniatures/HQ/HQ_095_06.png",
        "miniatures/HQ/HQ_095_07.png","miniatures/HQ/HQ_095_08.png","miniatures/HQ/HQ_095_09.png",
        "miniatures/HQ/HQ_095_10.png","miniatures/HQ/HQ_095_11.png","miniatures/HQ/HQ_095_12.png",
        "miniatures/HQ/HQ_095_13.png","miniatures/HQ/HQ_095_14.png","miniatures/HQ/HQ_095_15.png",
        "miniatures/HQ/HQ_095_16.png","miniatures/HQ/HQ_095_17.png","miniatures/HQ/HQ_095_18.png",
        "miniatures/HQ/HQ_095_19.png"
    ],
    miniatura: "miniatures/TL/TL_095.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "096",
    data: "1713",
    titol: "Decisió de la Junta de Braços de resistir al setge borbònic (1713)",
    subtitol: "La defensa a ultrança",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_096_01.png","miniatures/LQ/LQ_096_02.png","miniatures/LQ/LQ_096_03.png",
            "miniatures/LQ/LQ_096_04.png","miniatures/LQ/LQ_096_05.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_096_01.png","miniatures/HQ/HQ_096_02.png","miniatures/HQ/HQ_096_03.png",
        "miniatures/HQ/HQ_096_04.png","miniatures/HQ/HQ_096_05.png"
    ],
    miniatura: "miniatures/TL/TL_096.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "097",
    data: "1714A",
    titol: "Carta del marquès del Poal (1714)",
    subtitol: "La batalla de Talamanca",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_097_01.png","miniatures/LQ/LQ_097_02.png","miniatures/LQ/LQ_097_03.png",
            "miniatures/LQ/LQ_097_04.png","miniatures/LQ/LQ_097_05.png","miniatures/LQ/LQ_097_06.png",
            "miniatures/LQ/LQ_097_07.png","miniatures/LQ/LQ_097_08.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_097_01.png","miniatures/HQ/HQ_097_02.png","miniatures/HQ/HQ_097_03.png",
        "miniatures/HQ/HQ_097_04.png","miniatures/HQ/HQ_097_05.png","miniatures/HQ/HQ_097_06.png",
        "miniatures/HQ/HQ_097_07.png","miniatures/HQ/HQ_097_08.png"
    ],
    miniatura: "miniatures/TL/TL_097.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "098",
    data: "1714B",
    titol: "Capitulació del duc de Berwick (1714)",
    subtitol: "La caiguda de Barcelona",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_098_01.png","miniatures/LQ/LQ_098_02.png","miniatures/LQ/LQ_098_03.png",
            "miniatures/LQ/LQ_098_04.png","miniatures/LQ/LQ_098_05.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_098_01.png","miniatures/HQ/HQ_098_02.png","miniatures/HQ/HQ_098_03.png",
        "miniatures/HQ/HQ_098_04.png","miniatures/HQ/HQ_098_05.png"
    ],
    miniatura: "miniatures/TL/TL_098.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "099",
    data: "1716",
    titol: "Decret de Nova Planta (1716)",
    subtitol: "La fi de les llibertats i les institucions catalanes",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_099_01.png","miniatures/LQ/LQ_099_02.png","miniatures/LQ/LQ_099_03.png",
            "miniatures/LQ/LQ_099_04.png","miniatures/LQ/LQ_099_05.png","miniatures/LQ/LQ_099_06.png",
            "miniatures/LQ/LQ_099_07.png","miniatures/LQ/LQ_099_08.png","miniatures/LQ/LQ_099_09.png",
            "miniatures/LQ/LQ_099_10.png","miniatures/LQ/LQ_099_11.png","miniatures/LQ/LQ_099_12.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_099_01.png","miniatures/HQ/HQ_099_02.png","miniatures/HQ/HQ_099_03.png",
        "miniatures/HQ/HQ_099_04.png","miniatures/HQ/HQ_099_05.png","miniatures/HQ/HQ_099_06.png",
        "miniatures/HQ/HQ_099_07.png","miniatures/HQ/HQ_099_08.png","miniatures/HQ/HQ_099_09.png",
        "miniatures/HQ/HQ_099_10.png","miniatures/HQ/HQ_099_11.png","miniatures/HQ/HQ_099_12.png"
    ],
    miniatura: "miniatures/TL/TL_099.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    },
    {id: "100",
    data: "1720",
    titol: "Nueva descripción geographica del Principado de Cataluña (1720)",
    subtitol: "La nova Catalunya borbònica",
    autor: "En Pau, en Pere i en Berenguera",
    comentari: "Lorem ipsum",
    ComentariEpub: "",
    miniatures: [
            "miniatures/LQ/LQ_100_01.png"
    ],
    imatgesHQ: [
        "miniatures/HQ/HQ_100_01.png"
    ],
    miniatura: "miniatures/TL/TL_100.png",
    imatge3D: "",
    tresD: false,
    HD: true,
    ePub: false,
    pdf: false
    }
]