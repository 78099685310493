import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get, set, update } from "firebase/database";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
apiKey: process.env.REACT_APP_API_KEY,
authDomain: "mementocathalonia-f0e42.firebaseapp.com",
projectId: "mementocathalonia-f0e42",
storageBucket: "mementocathalonia-f0e42.appspot.com",
messagingSenderId: "695670264562",
appId: "1:695670264562:web:beeec2a95fa421fb0c766b",
databaseURL : "https://mementocathalonia-f0e42-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
const dbRef = ref(database);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth();

// Checks if token is in the database and returns a callback function
//receives the token Id / user Id
//returns a callback(data, error)
export const CheckDatabase = (uid, callback) =>{    
    get(child(dbRef, 'TokenSessio/'+uid))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                callback(data, undefined);
            } else {
              callback(undefined, undefined);
            }
        }).catch((error) => {
        callback(undefined, error);
        });        
}

export const updateData = (tokenId, field, data) => {
    const updates = {};
    updates['TokenSessio/'+tokenId+'/'+field] = data;
    update(ref(database), updates);
}

export const createUser = (uid) => {
    set(ref(database, 'TokenSessio/' + uid), {
        dataCreacio: Date.now(),
        QRisScaned: false,
        PKisScaned: false,
        PKisValid: false,
        Nserie: ""
      });
}

export const AuthenticationState = () =>{
    onAuthStateChanged(auth, (user)=>{
        if (user) {
            CheckDatabase(user.uid, (data, error) =>{
                if(!data) createUser(user.uid);
            })            
            return user.uid;
          } else {
            AnonymousSignIn()
          }
    })
}

export const AnonymousSignIn = () => {
    signInAnonymously(auth)
    .then(() => {
        const userUID = AuthenticationState()
        console.log(userUID)        
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(errorMessage)
    });
}

