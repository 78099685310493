import { useNavigate, useLocation } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Fondo, BolaTemps, ImatgeLinea, ContainerNarrow } from './TimelineLibrary.styles';
import Capçal from '../../Capçal/Capçal.component';
import {medieval} from '../../../data/medieval.js'
import { auth, CheckDatabase } from '../../../utils/firebase.utils'
import { useEffect, useState } from 'react'


export default function TimelineLibrary() {

    const [numSerie, setNumSerie] = useState("")
    
    useEffect(() => {
        setTimeout(() => {
            //if(auth.currentUser) setConectat(true);
            CheckDatabase(auth.currentUser.uid, (data, error) =>{
                if(error){
                    console.log(error)
                }
                if(data) {
                    setNumSerie(data.Nserie);
                }    
              });         
        }, 300);
    
      }, [])
    
    const navigate = useNavigate()
    const location = useLocation()
    
    const iconaSeleccionada1 = (data)=>{
        //console.log("Primera...",data)
        navigate("/detalls",  { state: { data }})
    }

    return(
        <Fondo>
            <Capçal numSerie={numSerie} comingFrom={location.pathname} />
            <ContainerNarrow>
                <VerticalTimeline lineColor={"#03192a"}>
                {medieval.map((medieval, index)=>{
                    //console.log(medieval.imatge)
                    return(
                        <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element"
                        contentStyle={{ //color:"white",
                                        // width:"300px", 
                                        // height:"280px", 
                                        backgroundColor: "transparent", 
                                        boxShadow:"none"}}
                        //contentArrowStyle={{borderRight: '7px solid  rgb(250, 252, 252)' }}
                        // style={{ margin:"0px", 
                        //          padding:"0px",                              
                        //          fontSize: "30px"}}
                        //intersectionObserverProps={{ rootMargin: '0px 0px 0px 0px' }}
                        //dateClassName="vertical-timeline-element-date"
                        //date={medieval.data}
                        iconClassName="vertical-timeline-element-icon"                    
                        iconStyle={{ backgroundColor: "transparent", boxShadow:"none", border:"none"}}
                        icon={<BolaTemps/>}                    
                        >                           
                        <ImatgeLinea  url={medieval.miniatura} onClick={()=>{iconaSeleccionada1(medieval)}}/>                        
                        </VerticalTimelineElement>
                    )
                })}
                </VerticalTimeline> 
            </ContainerNarrow>       
        </Fondo>

    )
}