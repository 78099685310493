import styled from 'styled-components'
import AndorraPell from '../../resources/AndorraPell.jpg'


export const Fondo = styled.div`

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #011d33;
    /* background-image: url(${AndorraPell});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat; */
`;

export const Imatge = styled.img`
    
    margin: 6em;
    width: 70vw;
    height: 30vh;
`;

export const Titol = styled.h1`

    font-family: Helvetica;
    font-size: 70px;
    color: white;
`;

export const Paragraf = styled.p`

    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 40px;
    margin: 8vh;
    width: 70vw;
`;
