import styled from 'styled-components'
import ImatgeFondo from '../../resources/AndorraPell.jpg'
import Lupa from '../../resources/lupa.png'

export const Fondo = styled.div`

  display: flex;
  flex-direction: column;  
  justify-items: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  background-image: url(${ImatgeFondo});
  background-size: cover;
  background-position: center center ;
  background-repeat: no-repeat;

`;

export const Capçalera = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-top: 2em;
    width: 1145px;
    //height: 6vh;

    //border: 2px solid yellow;
`;

export const LogoMini = styled.img`
    width: 16em;
    height: 5em;
    align-self: center;
    padding-top: 0.5em;

    :hover{
        cursor: pointer;
    }
`;

export const Search = styled.input`
    
    background-image: url(${Lupa});
    background-repeat: no-repeat;
    background-size: 2rem;
    background-color: transparent;
    background-position: 0.4rem;
    text-indent: 3rem;
    height: 2rem;
    width: 15rem;
    border-radius: 23px;
    border-width: 2px;
    color: white;
    border-color: white;
    font-size: 2rem;
    align-self: center;    

`;

export const DivComentari = styled.div`
    
    width: 80%;
    margin: 1em;
    text-align: start;
    //height: 80%;
   // border: 2px solid blue;
`

export const DivIcones = styled.div`

    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
    //padding: 0.2em;
    //border: 2px solid red;

`

export const Icones = styled.img`
    width: 4em;
    height: 4em;
    align-self: center;
    padding-top: 0.5em;
    opacity: ${props => props.actiu ? "100%" : "20%"};

    :hover{
        opacity: ${props => props.actiu ? "60%" : "20%"};
        cursor: ${props => props.actiu ? "pointer": "auto"};
    }
    
`;

export const ContainerHorizontalNarrow = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1145px;
    //height: 100%;
    margin-top: 5px;
    padding: 0;
    z-index: 0;
    //border: 1px solid white;
`;

export const ContainerCarousel = styled.div`

    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    width: 1145px;
    //height: 100%;
    margin-top: 5px;
    padding: 0;
    z-index: 1;
    //border: 1px solid white;
`;

export const IconesCarousel = styled.div`

    position: absolute;
    z-index: 2;
    bottom : 100px;
    left: 69vw;
    //padding: 0.2em;
    //border: 2px solid red;

`

export const ContainerHorizontalNarrow2 = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1145px;
    //height: 100%;
    margin-top: 5px;
    padding: 0;
    //border: 1px solid white;
`;

export const ContainerHorizontal = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 10%;
    color: white;
    font-size: 25px;
    margin: 0;
    padding: 0;
    //border: 2px solid orange;
`;

export const ContainerVerticalNarrow = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1145px;
    height: 12%;
    margin-left: 6em;
    padding: 0.5em;
    //border: 2px solid yellow;
`;

export const ContainerVertical = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 20%;
    height: 80%;
    //padding: 1%;
    padding-bottom: 9%;
    //border: 2px solid black;
    /* justify-content: space-around; */
`;

export const ContainerVertical2 = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    height: 100%;
    margin: 1em;
    //border: 2px solid grey;
    /* justify-content: space-around; */
`;

export const Miniatures = styled.img`
    
    width: 1145px;
    height: 400px;
    padding: 0;
    margin: 0;
    background-image : url(${props => props.url});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat; 
    
    
`;

export const Anterior = styled.img`
    width: 2em;
    height: 2em;
    align-self: center;
    padding-top: 0.5em;
    transform: rotate(90deg);
    :hover{
        opacity:60%;
        cursor: pointer;
    }
    
`;

export const Posterior = styled.img`
    width: 2em;
    height: 2em;
    align-self: center;
    padding-top: 0.5em;
    transform: rotate(-90deg);
    :hover{
        opacity:60%;
        cursor: pointer;
    }
`;