import styled from 'styled-components'
import ImatgeFondo from '../../resources/AndorraPell.jpg'

export const Fondo = styled.div`

  display: flex;
  flex-direction: column;  
  justify-items: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  background-image: url(${ImatgeFondo});
  background-size: cover;
  background-position: center center ;
  background-repeat: no-repeat;

`;

export const Icones = styled.img`
    width: 4em;
    height: 4em;
    align-self: center;
    padding-top: 0.5em;

    :hover{
        opacity:60%;
        cursor: pointer;
    }
    
`;