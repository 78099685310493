import styled from 'styled-components'
import MementoScan from '../../resources/MementoScan.png'

export const ContainerVertical = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    /* justify-content: space-around; */
`;

export const ImatgeScan = styled.div`
    
    width: 300px;
    height: 300px;
    margin: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    background-image: url(${MementoScan});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat;
`;

export const Paragraf = styled.p`

    width: 300px;    
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 40px;
`;

export const Imatge = styled.img`

    width: 180px;
    height: 180px;
    /* border: 1px solid black; */
    position: relative;
    left: 7vw;
    top: -1vh;
`;