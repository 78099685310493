import {Fondo } from './Timeline.styles'
import Capçal1 from '../Capçal1/Capçal1.component'

export default function Timeline(){

    return(
        <Fondo>
            <Capçal1/>
        </Fondo>
    )
}