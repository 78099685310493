import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Logo, Paragraf2,
          ContainerHoritzontal, ContainerVertical, 
          Boto } from './mobile.styles';
import { auth, AuthenticationState, updateData } from '../../utils/firebase.utils';
import logo from '../../resources/LogoCat.png';
import {Connect} from '../../utils/CheckBalance.utils'


export default function Mobile() {

  useEffect(() => {
    setTimeout(() => {
        if(!auth.currentUser) AuthenticationState();
        // return setSessionToken(auth.currentUser.uid)
        updateData(auth.currentUser.uid, 'QRisScaned', false)
        updateData(auth.currentUser.uid, 'PKisScaned', false)
        updateData(auth.currentUser.uid, 'PKisValid', false)          
    }, 300);

  }, [])
  
  const navigate = useNavigate();
  const navega = () => {
      navigate("/AccesTokenSessio", { replace: true });
  };

  const Metamask = () => {
    Connect((address, balance)=> {
      console.log("account returned...", address)
      console.log("balance returned...", balance.toNumber())
      if(address!==null && balance.toNumber()===0){
        updateData(auth.currentUser.uid,"QRisScaned", true)
        updateData(auth.currentUser.uid,'PKisScaned', true)
        updateData(auth.currentUser.uid,'PKisValid', false)
      }
      else if(address!==null &&balance.toNumber()>0){
        updateData(auth.currentUser.uid,"QRisScaned", true)
        updateData(auth.currentUser.uid,'PKisScaned', true)
        updateData(auth.currentUser.uid,'PKisValid', true)
      }
    });
  }
  
  return (     
        <Container>
          <Logo src={logo}/>                            
          <ContainerVertical>
            <Paragraf2>ACCES RESTRINGIT AL CONTINGUT DIGITAL</Paragraf2>
            <Paragraf2>
            MEMENTO edicions ha dipositat en servidors web contingut digital exclusiu. 
            L’accés a aquest contingut digital és restringit i es realitza mitjançant la verificació 
            de propietat al registre blockchain. Només els propietaris del token d’accés 
            creat a l’efecte hi tenen permesa l’entrada.
            </Paragraf2>
            <Paragraf2>
            Per accedir  al contingut digital restringit, 
            MEMENTO edicions ha creat un MementoCathaloniaToken  (MmCaT), 
            que s’entrega en carteres fredes o carteres fora de línia (cold wallets) 
            anomenades PaperWallets.
            </Paragraf2>
            <Paragraf2>
            La PaperWallet vinculada al projecte Cathalonia 815-1720 proporciona dos codis QR. 
            El primer, serveix per reconèixer el producte i identificar el número de token; 
            el segon, constitueix la clau privada que dona accés als continguts digitals. 
            </Paragraf2>
            <Paragraf2>
            Pot accedir al contingut digital utilitzant els codis QR de que disposa 
            o directament a través del web descentralitzat MetaMask.
            </Paragraf2>                
          </ContainerVertical>
          <ContainerHoritzontal>
            <Boto onClick={navega} ><b>Accedeix amb </b>QR</Boto>
            <Boto onClick={Metamask} ><b>Accedeix amb </b>Metamask</Boto>
          </ContainerHoritzontal>           
        </Container>
  )
}