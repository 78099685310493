import styled from "styled-components";
import ImatgeBolatemps from '../../../resources/BolaTemps.png'
import Lupa from '../../../resources/lupa.png'

export const Fondo = styled.div`

  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  background-color: #011d33;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 20px;

`;

export const BolaTemps = styled.img`

  height: 60px;
  width: 60px;
  background-image : url(${ImatgeBolatemps});
  background-size: cover;
  background-position: center center ;
  background-repeat: no-repeat;

`;

export const ImatgeLinea = styled.img`
  
  height: 200px;
  width: 400px;
  margin: 0;
  padding: 0;
  background-image : url(${props => props.url});
  background-size: cover;
  background-position: center center ;
  background-repeat: no-repeat; 
  
  :hover{
        cursor: pointer;
    }

`

export const ContainerNarrow = styled.div`

    width: 1145px;
    color: white;
    margin-top: 80px;
    //border: 2px solid red;
`;