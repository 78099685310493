import {Capçalera, Search, LogoMini, Icones, 
        IconaMenu, NavMenu, LinkMenu, NSerie } from './Capçal.styles'
import logo from '../../resources/LogoCat.png'
import Back50 from '../../resources/Back100.png'
import Hamburguesa from '../../resources/Hamburguesa.png'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Capçal({numSerie, comingFrom, data}){

    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    //console.log("capçal comingFrom", comingFrom)
    //console.log("data...??",data)
    
    function Reset(){
        navigate("/")
    }

    const Back = () =>{
        switch(comingFrom){
            case "/Papir3D":
                navigate("/detalls",  { state: { data }}) //
                break;
            case("/detalls"):
                navigate("/TimelineLibrary")
                break;
            case("/TimelineLibrary"):
                navigate("/Timeline")
                break;
            default: 
            navigate(-1)
            break;
        }
        //navigate("/TimelineLibrary")
        //console.log("li has donat enrere")
    }

    const Menu = () =>{
        setVisible(!visible)
    }

    const Epub = (index) =>{
        setVisible(!visible)
        console.log("index clicat....",index.target.innerHTML)
        switch (index.target.innerHTML) {
            case "Presentació":
                navigate("/Epub",  { state: {URL:"1-Presentacio.epub", numSerie} })
                break;
            case "Nota de l'editor":
                navigate("/Epub",  { state: {URL:"2-NotaDelEditor.epub", numSerie} })
                break;
            case "Catalunya, terra de documents":
                navigate("/Epub",  { state: {URL:"3-CatalunyaTerraDeDocuments.epub", numSerie} })
                break;        
            default: break;
        }
        //window.open("/Epub", { state: { index }},"", "popup, menubar=no, titlebar=no, toolbar=no")
}

    return(
        <>
            <Capçalera>
                <LogoMini  src={logo} onClick={Reset} />
                <Icones src={Back50} actiu="true" onClick={Back} />
                <Search/>
                <IconaMenu src={Hamburguesa} actiu="true" onClick={Menu} />
                <NSerie style={{padding:"1.2rem"}} >{numSerie}</NSerie>    
            </Capçalera>
            {visible &&
            <Capçalera>
                <NavMenu>
                    <LinkMenu onClick={Epub} >Presentació</LinkMenu>
                    <LinkMenu onClick={Epub} >Nota de l'editor</LinkMenu>
                    <LinkMenu onClick={Epub} >Normes de transcripció i edició</LinkMenu>
                    <LinkMenu onClick={Epub} >Sigles i abreviatures</LinkMenu>
                    <LinkMenu onClick={Epub} >Col·laboradors</LinkMenu>
                    <LinkMenu onClick={Epub} >Catalunya, terra de documents</LinkMenu>
                </NavMenu>
            </Capçalera>           
            }
        </>

    )
}