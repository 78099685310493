import React from 'react';
import QRCode from "react-qr-code";

// prop	    type	 default value
// bgColor	string	 '#FFFFFF'
// fgColor	string	 '#000000'
// level	string   ('L' 'M' 'Q' 'H')	'L'
// size	    number	 256
// title	string	
// value	string	

const GeneraQR = ({id, title, value, size, bgColor, fgColor, level }) => {

    return (<QRCode 
                    id={id}            
                    bgColor={bgColor} 
                    fgColor={fgColor} 
                    level={level} 
                    title={title} 
                    size={size} 
                    value={value}/>);

};

export default GeneraQR;