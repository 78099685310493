import {Button, Cuadro, ContainerVerticalNarrow2,
        ContainerHorizontalNarrow, ContainerVertical,
        ContainerVerticalNarrow, ContainerHorizontalNarrow2,
        Button2 } from './Capçal1.styles'
import Capçal from '../Capçal/Capçal.component'
import { useNavigate, useLocation} from 'react-router-dom'
import { auth, CheckDatabase } from '../../utils/firebase.utils'
import { useEffect, useState } from 'react'



export default function Capçal1(){

    const [conectat, setConectat] = useState(false)
    const [numSerie, setNumSerie] = useState("")
    
    useEffect(() => {
        setTimeout(() => {
            if(auth.currentUser) setConectat(true);
            CheckDatabase(auth.currentUser.uid, (data, error) =>{
                if(error){
                    console.log(error)
                }
                if(data) {
                    setNumSerie(data.Nserie);
                }    
              });         
        }, 300);
    
      }, [])

    const navigate = useNavigate();
    const location = useLocation()

    function navegaTimeline(){
        navigate("/TimelineLibrary")
    }

    const Epub = (index) =>{
        console.log("index clicat....",index.target.innerText)//
        //window.open("/Epub","", "popup, menubar=no, titlebar=no, toolbar=no")
        switch (index.target.innerText) {
            case "Presentació":
                navigate("/Epub",  { state: {URL:"1-Presentacio.epub", numSerie} })
                break;
            case "Nota de l'editor":
                navigate("/Epub",  { state: {URL:"2-NotaDelEditor.epub", numSerie} })
                break;
            case "Catalunya, terra de documents":
                navigate("/Epub",  { state: {URL:"3-CatalunyaTerraDeDocuments.epub", numSerie} })
                break;
            case ("De la conquesta carolíngia al “mal any primer”: gènesi i consolidació d’unes estructures feudals (Antoni Riera i Melis)"):
                navigate("/Epub",  { state: {URL:"4-DeLaconquestaCarolingia.epub", numSerie} })
                break;
            case ("Del “mal any primer” a la sentència de Guadalupe: les crisis i les reformes de la Baixa Edat Mitjana (Antoni Riera i Melis)"):
                navigate("/Epub",  { state: {URL:"5-DelMalAnyPrimer.epub", numSerie} })
                break;
            case ("De la unió de corones al Decret de Nova Planta. Entre el redreç i el creixement (Jaume Dantí i Riu)"):
                navigate("/Epub",  { state: {URL:"6-DeLaUnioDeCorones.epub", numSerie} })
                break;           
            default: break;
        }
    }

    return(
        <ContainerVertical>                
            <Capçal numSerie={numSerie} comingFrom={location.pathname} />
            <ContainerVerticalNarrow>
                <ContainerHorizontalNarrow>
                    <Button onClick={Epub}>Presentació</Button>
                    <Button onClick={Epub}>Nota de l'editor</Button>
                    <Button onClick={Epub}>Catalunya, terra de documents</Button>
                </ContainerHorizontalNarrow>
                <ContainerHorizontalNarrow>
                    <Cuadro>Catalunya Medieval</Cuadro>
                    <Cuadro>Catalunya Moderna</Cuadro>                        
                </ContainerHorizontalNarrow>
                <ContainerHorizontalNarrow>
                    <ContainerVerticalNarrow2>
                        <Button2 onClick={Epub}> De la conquesta carolíngia al “mal any primer”: 
                            gènesi i consolidació d’unes estructures feudals 
                            (Antoni Riera i Melis)
                        </Button2>
                        <Button2 onClick={Epub}>Del “mal any primer” a la sentència de Guadalupe: 
                            les crisis i les reformes de la Baixa Edat Mitjana 
                            (Antoni Riera i Melis)
                        </Button2>
                    </ContainerVerticalNarrow2>
                    <ContainerVerticalNarrow2>
                        <Button2 onClick={Epub}>De la unió de corones al Decret de Nova Planta. 
                            Entre el redreç i el creixement (Jaume Dantí i Riu)
                        </Button2>
                    </ContainerVerticalNarrow2>
                </ContainerHorizontalNarrow>
                <ContainerHorizontalNarrow>

                    <ContainerHorizontalNarrow2>
                        <Button onClick={navegaTimeline} >Indèx de Documents</Button>
                    </ContainerHorizontalNarrow2>

                </ContainerHorizontalNarrow>                   
            </ContainerVerticalNarrow>                
        </ContainerVertical>
    )
}