import GeneraQR from "../GeneraQR/GeneraQR.component";
import { BaseContainer, BaseImatge } from "./ImatgeQR.styles";

export default function ImatgeQR({value}) {

    return(
        <>
              <BaseContainer>
              <BaseImatge>
                  <GeneraQR 
                      id={1}            
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      level="H"
                      title="Token Session"
                      size={260} 
                      value={value}/>
              </BaseImatge>      
          </BaseContainer>           
        </>        
    );
};