import styled from "styled-components";
import baseQr from '../../resources/MementoScan.png';

export const BaseContainer = styled.div`

    width: 500px;
    height: 500px;
    margin-bottom: 0.1%;
    background-color: #011d33;
    background-image: url(${baseQr});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat;

`;

export const BaseImatge = styled.div`

    height: 250px;
    width: 250px;
    margin-top: 110px;
    margin-left: 180px;
    /* border: 1px solid #000; */

`;