import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckDatabase, updateData } from '../../utils/firebase.utils'
import PantallaMobil from "../../components/PantallaMobil/PantallaMobil.component";

export default function MobileSlug() {

    const {tokenId} = useParams();
    // const url = window.location.href;
    const [response, setResponse] = useState({});

    useEffect(() => {
        CheckDatabase(tokenId, (data, error) =>{
            if(error){
                console.log(error)
            }
            if(data){
                setResponse(data);
                console.log('token...', tokenId)
                console.log('resposta del check..',data)
                updateData(tokenId, 'QRisScaned', true);
            }else{
                setResponse({});
                console.log('there is no entry at database from this token')
            }        
        });
    }, []);   

    return(
        <div>
            <PantallaMobil token={tokenId}/>
        </div>
    );
};