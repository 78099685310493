import { Fondo, Imatge, Titol, Paragraf } from './EscaneixClauPrivada.styles'
import Logo from '../../resources/LogoCat.png'
import { useEffect, useState } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'
import { app, auth } from '../../utils/firebase.utils'
import PrivadaErronia from '../PrivadaErronia/PrivadaErronia.component'
import Timeline from '../Timeline/Timeline.component'

export default function EscaneixClauPrivada() {

    const [isScaned, setIsScaned] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {

        const database = getDatabase(app)
        const dataRoute = 'TokenSessio/'+auth.currentUser.uid+'/PKisScaned'
        const validesa = ref(database, dataRoute)
        onValue(validesa, (snapshot) => {
            const data = snapshot.val()
            setIsScaned(data)            
        });

        const dataRoute2 = 'TokenSessio/'+auth.currentUser.uid+'/PKisValid'
        const validesa2 = ref(database, dataRoute2)
        onValue(validesa2, (snapshot2) => {
            const data2 = snapshot2.val()
            setIsValid(data2)            
        });
    }, [])

    if(isScaned && !isValid){
        return <PrivadaErronia/>
    }
    else if(isScaned && isValid){
        return <Timeline/>
    }    

    return(
        <>
            <Fondo>
            <Imatge src={Logo}/>
            <Paragraf>
                Per accedir als continguts digitals exclusius, escanegi amb
                el seu telèfon mòbil el còdi QR privat.
            </Paragraf>
            </Fondo>
        </>
   
    )
}