import styled from 'styled-components';

export const Container = styled.div`
    
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    //width: 1145px;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: #011d33;
`;

export const Logo = styled.img`
    width: 1145px;
    //height: 30vh;
`;

export const Paragraf = styled.p`

    background-color: #011d33;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 35px;
    margin: 4vh;
`;