import styled from 'styled-components'
import ImatgeFondo from '../../resources/AndorraPell.jpg'
import Lupa from '../../resources/lupa.png'

export const Capçalera = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin: 0;
    margin-top: 1em;
    width: 1145px;
    height: 6vh;
    //border: 2px solid yellow;
`;

export const NavMenu = styled.nav`
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    transition: all 0.6s ease;
    margin-top: 35px;

`;

export const LinkMenu = styled.a`

    color: white;
    font-size: 22px;
    text-decoration: none;
    //font-weight: bold;

`;

export const Icones = styled.img`
    //width: 4em;
    height: 3.5em;
    align-self: center;
    padding-top: 0.5em;
    opacity: ${props => props.actiu ? "100%" : "20%"};

    :hover{
        opacity: ${props => props.actiu ? "60%" : "20%"};
        cursor: ${props => props.actiu ? "pointer": "auto"};
    }    
`;

export const IconaMenu = styled.img`
    //width: 4em;
    height: 2em;
    align-self: center;
    padding-top: 0.5em;
    opacity: ${props => props.actiu ? "100%" : "20%"};

    :hover{
        opacity: ${props => props.actiu ? "60%" : "20%"};
        cursor: ${props => props.actiu ? "pointer": "auto"};
    }
    
`;

export const ContainerVertical = styled.div`

    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-items: center;
    color: white;
    background-image: url(${ImatgeFondo});
    background-size: cover;
    background-position: center center ;
    background-repeat: no-repeat;
    //background: linear-gradient(145deg, rgb(80,20,20), rgb(130,35,40));
    //border: 2px solid white;
`;

export const ContainerVerticalNarrow = styled.div`

    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    width: 1145px;
    height: 80vh;
    //margin-left: 10vw;
    margin-top: 6%;
    padding: 2em;
    padding-top: 0;
    align-items: flex-start;
    justify-self: center;
    color: white;
    //border: 2px solid red;
`;

export const ContainerVerticalNarrow2 = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 575px;
    margin: 0;
    padding: 0;
    color: white;
    //border: 2px solid red;
`;

export const ContainerHoritzontal = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 1145px;
    //height: 80vh;
    //border: 2px solid black;
`;

export const ContainerHorizontalNarrow = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1145px;
    //margin-left: 6em;
    padding: 0.5em;
    //border: 2px solid orange;
`;

export const ContainerHorizontalNarrow2 = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1145px;
    //margin-left: 6em;
    padding: 0.5em;
    //border: 2px solid orange;
`;

export const ContainerTitols = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 1145px;
    margin-left: 7rem;
    padding-top: 0;
    padding: 0;
    //border: 2px solid orange;
`;

export const Titol = styled.h2`

    color: whitesmoke;
    font-family: Helvetica;
    font-size: 25px;
    :hover{
        border: 1px solid white;
        border-radius: 6px;
        padding: 10px;
        margin: 0;
        cursor: pointer;
    }
`;

export const Search = styled.input`
    
    background-image: url(${Lupa});
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-color: transparent;
    background-position: 0.4rem;
    text-indent: 3rem;
    height: 2rem;
    width: 15rem;
    border-radius: 23px;
    border-width: 2px;
    color: white;
    border-color: white;
    font-size: 2rem;
    align-self: center;    

`;

export const Logo = styled.img`
    width: 55em;
    height: 15em;
    align-self: center;
    
`;

export const Epub = styled.img`
    width: 5em;
    height: 5em;
`;

export const LogoMini = styled.img`
    width: 18em;
    height: 6em;
    align-self: center;
    padding-top: 0.5em;

    :hover{
        cursor: pointer;
    }
`;

export const Button = styled.button`
    
    border: 1px solid white;
    border-radius: 9px;
    height: 6em;
    width: 22em;
    color: white;
    font-size: 1em;
    font-weight: bold;
    background-color: transparent;

    :hover{
        background-color: #dddbdb;
        color: black;
        cursor: pointer;
    }
`;

export const Button2 = styled.button`
    
    border: 1px solid white;
    margin-top: 15px;
    border-radius: 9px;
    height: 100px;
    width: 550px;
    color: white;
    font-size: 1em;
    font-weight: bolder;
    background-color: transparent;

    :hover{
        background-color: #dddbdb;
        color: black;
        /* box-shadow: 5px 5px;
        transition: all 0.6s ease;
        transform: translate(-5px, -5px) scale(1.01); */
        cursor: pointer;
    }
`;

export const Cuadro = styled.label`
    
    //border: 1px solid white;
    border-radius: 10px;
    height: 2em;
    width: 29em;
    text-align: center;
    text-justify: center;
    font-size: 18px;
    font-weight: bold;
    padding: 1rem;
`;

export const Cuadro2 = styled.label`
    
    flex-wrap: wrap;
    //border: 1px solid white;
    height: 16rem;
    width: 27rem;
    text-align: start;
    text-justify: center;
    font-size: 15px;
    margin: 0.5rem;
    margin-top: 0;
    padding: 1rem;
`