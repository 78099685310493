import { ethers } from "ethers";
import Web3Modal from "web3modal";
import nftContract from '../contracts/Editorial.json';

const NFT_CONTRACT_ADDRESS = '0x74D2707CE861Eb336C2bc779D4Ba92067E469363';
// const polygonChainId = "0x89";
const providerOptions = {};
const web3Modal = new Web3Modal({
network: "mainnet", // optional
cacheProvider: false, // optional
providerOptions // required
});

export const Connect = async (callback) => {

    const web3ModalProvider = await web3Modal.connect();
    console.log('web3modalprovider..', web3ModalProvider)
    const provider = new ethers.providers.Web3Provider(web3ModalProvider);
    console.log('provider..', provider)
    let address = ''
    let balance = null

    async function setAccountFromProvider() {
        const signer = provider.getSigner(0);
        console.log('ethers signer...', signer)
        console.log('wallet...', signer.provider.connection.url)
        address = await signer.getAddress();
        nftBalance(address, signer, (balance)=>{
            callback(address, balance) 
        });
            
    }
    setAccountFromProvider();

    web3ModalProvider.on("accountsChanged", () => {
        setAccountFromProvider();
    });
      
}

//   Call balanceOf() function in contract to check NFT balance in connected wallet
const nftBalance = async (address, signer, callback) => {
    try {
        const tokenId = ethers.BigNumber.from(2)
        const connectedContract = new ethers.Contract( NFT_CONTRACT_ADDRESS, nftContract.abi, signer );
        let balance = await connectedContract.balanceOf( address, tokenId );

        console.log(`Your address ${address} has ${balance} NFT` );
        callback(balance)

    } catch ( error ) {
        console.log( error );
    }
}