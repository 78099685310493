import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactReader } from 'react-reader'
import { Fondo, Container } from './Epub.styles'
import { epubDocs } from '../../data/epubs'
import Capçal from '../Capçal/Capçal.component'

export default function Epub() {

  const lloc = useLocation()
  
  // And your own state logic to persist state
  const [location, setLocation] = useState(null)
  const locationChanged = epubcifi => {
    // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
    setLocation(epubcifi)
  }
  const URL = process.env.PUBLIC_URL +"/epubs/"+ lloc.state.URL
  console.log("el lloc...", lloc)
  return (
    <Fondo>
      <Capçal numSerie={lloc.state.numSerie} comingFrom={lloc.pathname} />
      <Container>
        <ReactReader
          location={location}
          locationChanged={locationChanged}
          url={URL}
          epubOptions={{
            allowPopups: true, // Adds `allow-popups` to sandbox-attribute
            allowScriptedContent: true, // Adds `allow-scripts` to sandbox-attribute
          }}
          //url="https://react-reader.metabits.no/files/alice.epub"
        />
      </Container>    
    </Fondo>
  )
}